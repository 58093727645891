

import { Container, Label } from './styles';
import TextField from '@mui/material/TextField';

interface TextfieldProps {
    label: string;
    width: number,
    disabled: boolean;
    value: string;
    fontSize: number;
    onChange: (arg0: string) => void;
    multiline: boolean;
    minLength?: number;
    maxLength?: number;
}

export default function CustomizedTextfield({ label, width, disabled, fontSize, value, multiline, minLength, maxLength, onChange }: TextfieldProps) {

    return (
        <Container>
            <Label style={{ fontSize: fontSize }}>{label}:</Label>
            <TextField
                variant="outlined"
                size="small"
                disabled={disabled}
                value={value}
                multiline={multiline}
                rows={4}
                onChange={(event) => onChange(event.target.value)}
                sx={{
                    borderRadius: '10px',
                    border: '0.2px solid #8E92BC',
                    '& input': {
                        color: '#141522',
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: fontSize,
                    }
                }}
                style={{
                    width: width,
                    color: '#141522',
                    background: '#FFFFFF',
                    fontSize: fontSize
                }}
                inputProps={{
                    maxLength: maxLength || undefined, 
                    minLength: minLength || undefined,
                }}
            />
        </Container>
    )
}