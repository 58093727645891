import { useState, useContext, useEffect } from "react";
import Button from "../../../components/Button";
import Numberfield from "../../../components/Numberfield";
import { RawDatepicker } from "../../../components/Datepicker/index";
import Textfield from "../../../components/Textfield";
import { Container, Header, Row, Content, Explanation, Divider } from './styles';
import Modal from '@mui/material/Modal';
import Spinner from "../../../components/Spinner";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UseProducts } from "../../../hooks/useProducts";
import Autocomplete from "../../../components/Autocomplete";
import AppContext from "../../../state/App.context";
import Table from '../../../components/Table';
import { UseFinishedProductOrders } from "../../../hooks/useFinishedProductOrders";
import ModalRemoveCost from "../ModalRemoveCost";
import { Close } from "../../../assets/icons";
import { UseProviders } from "../../../hooks/useProviders";
import { UsePaymentTypes } from "../../../hooks/usePaymentTypes";
import moment from "moment";
import TableNestedItemsAndParcels from "../../../components/TableNestedItemsAndParcels";
import { formatMoney } from "../../../services/functions";
import ModalVisualizeCost from "../ModalVisualizeCost";

const columns = [
  {
    name: 'Data de Compra',
    key: 'purchase_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Fornecedor',
    key: 'supplier_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Desconto',
    key: 'discount',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Tipo de Pagamento',
    key: 'payment_type_name',
    type: 'string',
    unit: '',
  },
]

const columnsParcels = [
  {
    name: 'Nº',
    key: 'number',
    type: 'number',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Vencimento',
    key: 'expiration_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Pagamento',
    key: 'payment_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'string',
    unit: '',
  },

]

const statusOptions = [
  {
    id: 0,
    label: 'Pendente'
  },
  {
    id: 1,
    label: 'Executado',
  }
]

const columnsItems = [
  {
    name: 'Insumo',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Categoria de Custo',
    key: 'cost_category_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Preço Unitário',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
]

export default function ModalManageCosts({ open, width, height, selectedItem, setSelectedItem, setRefreshData }) {
  const { costCategories } = UseCostCategories();
  const { products } = UseProducts();
  const { providers } = UseProviders();
  const { paymentTypes } = UsePaymentTypes();
  const { getFinishedProductOrderCosts, createFinishedProductOrderCost, updateFinishedProductOrderCost } = UseFinishedProductOrders();

  const [productAmount, setProductAmount] = useState(0);
  const [unityPrice, setUnityPrice] = useState(0);
  const [costCategory, setCostCategory] = useState({ id: 0, label: '' });
  const [supplier, setSupplier] = useState({ id: 0, label: '' });
  const [product, setProduct] = useState({ id: 0, label: '' });
  const [paymentType, setPaymentType] = useState({ id: 0, label: '' });
  const [discount, setDiscount] = useState(0);
  const [notes, setNotes] = useState('');
  const [purchaseDate, setPurchaseDate] = useState(moment().format('YYYY-MM-DD'));

  const [parcelValue, setParcelValue] = useState(0);
  const [expirationDate, setExpirationDate] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [status, setStatus] = useState(statusOptions[0]);

  const [selectedParcel, setSelectedParcel] = useState(null);
  const [buttonParcelLabel, setButtonParcelLabel] = useState('Adicionar');
  const [parcelsAmount, setParcelsAmount] = useState(0);
  const [parcelsList, setParcelsList] = useState([]);

  const [itemsList, setItemsList] = useState([]);
  const [buttonItemLabel, setButtonItemLabel] = useState('Adicionar');
  const [itemsAmount, setItemsAmount] = useState(0);
  const [selectedItemInput, setSelectedItemInput] = useState(null);

  const [costsList, setCostsList] = useState([]);

  const [selectedCostItem, setSelectedCostItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [showModalRemoveCost, setShowModalRemoveCost] = useState(false);
  const [showModalVisualizeCost, setShowModalVisualizeCost] = useState(false);

  const [refreshDataFromServer, setRefreshDataFromServer] = useState(0);

  const [finishedProductOrderId, setFinishedProductOrderId] = useState('');

  const [costToEdit, setCostToEdit] = useState(null);

  useEffect(() => {
    async function getCosts() {
      setCostsList([]);
      let id_finished_product_order = '';

      if (selectedItem.finished_product_order_cost && selectedItem.finished_product_order) {
        //finantial schedule screen
        id_finished_product_order = selectedItem.finished_product_order;
      } else {
        //modal finished product order modal
        id_finished_product_order = selectedItem.item?.id_finished_product_order;
      }

      setFinishedProductOrderId(id_finished_product_order);
      let result = await getFinishedProductOrderCosts(id_finished_product_order);

      if (result.success) {
        const costListToShow = [];

        result.data.forEach((cost) => {
          const costFormatted = {
            id: cost.id,
            finished_product_order: cost.finished_product_order,
            supplier: cost.supplier,
            supplier_name: providers.find((supplier) => supplier.id_providers === cost.supplier)?.providers_name,
            discount: cost.discount,
            notes: cost.notes,
            payment_type: cost.payment_type,
            payment_type_name: paymentTypes.find((paymentType) => paymentType.id_paymenttype === cost.payment_type).paymenttype_name,
            parcels: cost.parcels?.map((parcel) => ({
              number: parcel.number,
              value: parcel.value,
              expiration_date: parcel.expiration_date,
              payment_date: parcel.payment_date,
              status: parcel.status,
            })),
            total_value: cost.items?.reduce((total, item) => total + (item.amount * item.unit_price), 0) - cost.discount,
            items: cost.items?.map((item, index) => ({
              number: index + 1,
              product: item.product,
              product_name: products.find((product) => product.id_product === item.product)?.product_name,
              amount: item.amount,
              unit_price: item.unit_price,
              total_value: item.amount * item.unit_price,
              cost_category: item.cost_category,
              cost_category_name: costCategories.find((costCategory) => costCategory.id_category === item.cost_category)?.costs_categories_name,
            })),
            type: cost.type,
            purchase_date: cost.purchase_date,
          };

          if (selectedItem.finished_product_order_cost && (selectedItem.finished_product_order_cost === cost.id)) {
            setCostToEdit(costFormatted);
          }

          costListToShow.push(costFormatted);
        });

        setCostsList(costListToShow.filter((cost) => cost.type === 'Despesa Geral'));
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Não foi possível obter a lista de custos do pedido de produto acabado: ' + result.message,
        });
      }
    }

    getCosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDataFromServer]);

  function cleanAllInputs() {
    setProductAmount(0);
    setUnityPrice(0);
    setCostCategory({ id: 0, label: '' });
    setProduct({ id: 0, label: '' });
    setSupplier({ id: 0, label: '' });
    setPaymentType({ id: 0, label: '' });
    setDiscount(0);
    setNotes('');
    setParcelValue(0);
    setExpirationDate('');
    setPaymentDate('');
    setStatus(statusOptions[0]);
    setParcelsAmount(0);
    setItemsAmount(0);
    setParcelsList([]);
    setItemsList([]);
    setButtonParcelLabel('Adicionar');
    setButtonItemLabel('Adicionar');
    setSelectedParcel(null);
    setSelectedItemInput(null);
    setPurchaseDate(moment().format('YYYY-MM-DD'));
  }

  function cleanAll() {
    cleanAllInputs();
    setSelectedCostItem(null);
  }

  let costCategoriesOptions = costCategories && !costCategories.error && costCategories.map((element) => {
    return {
      id: element?.id_category,
      label: element?.costs_categories_name,
    }
  })

  let productsToShow = products && !products.error && products.filter(a => a.type === 'Insumo');

  let productsOptions = [];

  productsToShow && productsToShow.forEach((element) => {
    if (element.isactive)
      productsOptions.push({
        id: element?.id_product,
        label: element?.product_name,
      });
  })

  productsOptions.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

  let suppliersOptions = providers && !providers.error && providers.filter(a => a.type === 'Fornecedor').map((supplier) => ({
    id: supplier?.id_providers,
    label: supplier?.providers_name,
  }));

  let paymentTypesOptions = paymentTypes && !paymentTypes.error && paymentTypes.map((element) => {
    return {
      id: element?.id_paymenttype,
      label: element?.paymenttype_name,
    }
  });

  let parcelsSum = 0;

  for (let i = 0; i < parcelsList.length; i++) {
    if (parcelsList[i]?.value !== undefined && !isNaN(parcelsList[i]?.value)) {
      if (buttonParcelLabel === 'Adicionar') {
        parcelsSum += parcelsList[i]?.value;
      } else if (buttonParcelLabel === 'Alterar') {
        if (parcelsList[i]?.number !== selectedParcel.number) {
          parcelsSum += parcelsList[i]?.value;
        }
      }
    }
  }

  let sumTotalValueItems = 0;

  for (let i = 0; i < itemsList.length; i++) {
    if (buttonItemLabel === 'Adicionar') {
      sumTotalValueItems += itemsList[i]?.total_value;
    } else if (buttonItemLabel === 'Alterar') {
      if (itemsList[i]?.id !== selectedItemInput.id) {
        sumTotalValueItems += itemsList[i]?.total_value;
      }
    }
  }

  let costValue = sumTotalValueItems - discount;

  if (costToEdit) {
    handleSelectedCostItem({
      open: true,
      mode: "edit",
      ...costToEdit,
    });

    setCostToEdit(null);
  }

  async function saveOrEditCostAndUpdateList(event) {
    try {
      setLoading(true);
      setDisableSaveButton(true);
      event.preventDefault();

      if ((itemsList.length > 0) && supplier.id &&
        paymentType && paymentType.id && (discount >= 0) && status && status.label &&
        purchaseDate && moment(purchaseDate).isValid()) {
        if (((paymentType.label !== 'Parcelado') && (moment(expirationDate).isValid())) || ((paymentType.label === 'Parcelado') && (parcelsSum === costValue))) {
          let cost_id = null;

          if (selectedCostItem && selectedCostItem.id) {
            cost_id = selectedCostItem.id;
          }

          let parcelsListAux = null;

          if (paymentType.label !== 'Parcelado') {
            parcelsListAux = [{
              number: 1,
              value: costValue,
              expiration_date: moment(expirationDate).isValid() ? expirationDate : null,
              payment_date: moment(paymentDate).isValid() ? paymentDate : null,
              status: status.label,
            }];

            setParcelsList([{
              ...parcelsListAux[0],
            }]);
          } else {
            parcelsListAux = parcelsList.map((parcel, index) => ({
              number: index + 1,
              value: parcel.value,
              expiration_date: moment(parcel.expiration_date).isValid() ? parcel.expiration_date : null,
              payment_date: moment(parcel.payment_date).isValid() ? parcel.payment_date : null,
              status: parcel.status,
            }));
          }

          const itemsListAux = itemsList.map((item) => ({
            product: item.product,
            unit_price: item.unit_price,
            amount: item.amount,
            cost_category: item.cost_category,
          }));

          if (cost_id) {
            let result = await updateFinishedProductOrderCost(cost_id, {
              supplier: supplier.id,
              payment_type: paymentType.id,
              discount: discount,
              notes: notes,
              parcels: parcelsListAux,
              items: itemsListAux,
              purchase_date: purchaseDate,
            });

            if (result.success) {
              setSnack({
                open: true,
                severity: 'success',
                message: 'Custo de pedido de produto acabado atualizado com sucesso!',
              });

              if (selectedItem.finished_product_order_cost) {
                handleClose();
              } else {
                setRefreshDataFromServer((prevValue) => prevValue + 1);
                setSelectedCostItem(null);
                cleanAllInputs();
              }
            } else {
              setSnack({
                open: true,
                severity: 'error',
                message: 'Não foi possível atualizar o cadastro do custo de pedido acabado: ' + result.message,
              });
            }
          } else {
            let result = await createFinishedProductOrderCost({
              finished_product_order: finishedProductOrderId,
              supplier: supplier.id,
              payment_type: paymentType.id,
              discount: discount,
              notes: notes,
              parcels: parcelsListAux,
              items: itemsListAux,
              purchase_date: purchaseDate,
            });

            if (result.success) {
              setRefreshDataFromServer((prevValue) => prevValue + 1);
              setSelectedCostItem(null);
              cleanAllInputs();

              setSnack({
                open: true,
                severity: 'success',
                message: 'Custo de pedido de produto acabado cadastrado com sucesso!',
              });
            } else {
              setSnack({
                open: true,
                severity: 'error',
                message: 'Não foi possível realizar o cadastro do custo de pedido acabado: ' + result.message,
              });
            }
          }
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: 'A soma das parcelas deve corresponder ao valor total do insumo!',
          });
        }
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Preencha todos os campos necessários!',
        });
      }
    } catch (err) {
      console.log(err);
      setSnack({
        open: true,
        severity: 'error',
        message: 'err',
      });
    } finally {
      setLoading(false);
      setDisableSaveButton(false);
    }
  }

  function handleSelectedCostItem(item) {
    setSelectedCostItem(item);

    if (item.mode === 'edit') {
      setSupplier({ id: item.supplier, label: item.supplier_name });
      setPaymentType({ id: item.payment_type, label: item.payment_type_name });
      setDiscount(item.discount);
      setNotes(item.notes);

      if (item.payment_type_name !== 'Parcelado') {
        setExpirationDate(item.parcels[0].expiration_date);
        setPaymentDate(item.parcels[0].payment_date);

        if (item.parcels[0].status === 'Executado') {
          setStatus(statusOptions[1]);
        } else {
          setStatus(statusOptions[0]);
        }
      } else {
        setExpirationDate('');
        setPaymentDate('');
        setStatus(statusOptions[0]);
      }

      setParcelsList(item.parcels);
      setParcelsAmount(item.parcels.length);
      setItemsList(item.items);
      setItemsAmount(item.items.length);
      setPurchaseDate(item.purchase_date);
    }

    if (item.mode === 'remove') {
      setShowModalRemoveCost(true);
      cleanAllInputs();
    }

    if (item.mode === 'visualize') {
      setShowModalVisualizeCost(true);
    }
  }

  function handleClose() {
    cleanAll();

    if (selectedItem.finished_product_order_cost) {
      setSelectedItem({ open: false, mode: '', name: '', id_order: null });
      setRefreshData(prevIsRefreshData => !prevIsRefreshData);
    } else {
      setSelectedItem({ open: false, mode: 'view', item: null });
    }
  }

  function addOrSaveParcelToList() {
    if ((parcelValue > 0) && expirationDate && moment(expirationDate).isValid() && status && status.label) {
      if (parcelValue + parcelsSum <= costValue) {
        try {
          let parcelsCopy = [...parcelsList];

          if (buttonParcelLabel === 'Adicionar') {
            let parcelInfo = {
              number: parcelsAmount + 1,
              value: parcelValue,
              expiration_date: expirationDate,
              payment_date: paymentDate,
              status: status.label,
            }

            parcelsCopy.push(parcelInfo);
            setParcelsList(parcelsCopy);
            setParcelsAmount(parcelsAmount + 1);
          }

          if (buttonParcelLabel === 'Alterar') {
            let parcelInfo = {
              ...selectedParcel,
              value: parcelValue,
              expiration_date: expirationDate,
              payment_date: paymentDate,
              status: status.label,
            };

            let index = parcelsCopy.findIndex((element) => element.number === selectedParcel.number);
            parcelsCopy.splice(index, 1, parcelInfo);
            setParcelsList(parcelsCopy);
            setSelectedParcel(null);
            setButtonParcelLabel('Adicionar');
          }

          setParcelValue(0);
          setExpirationDate('');
          setPaymentDate('');
          setStatus(statusOptions[0]);
        } catch (err) {
          console.log(err);
        }
      } else {
        setParcelValue(costValue - parcelsSum);

        setSnack({
          open: true,
          severity: 'error',
          message: 'O somatório das parcelas será maior do que o valor do insumo. O valor da parcela foi atualizado para o máximo permitido.',
        });
      }
    } else {
      setSnack({
        open: true,
        severity: 'error',
        message: 'O valor e a data de vencimento devem ser informados.',
      });
    }
  }

  function addOrSaveItemToList() {
    if (product && product.label && costCategory && costCategory.label && productAmount && unityPrice) {
      try {
        let itemsCopy = [...itemsList];

        if (buttonItemLabel === 'Adicionar') {
          let itemInfo = {
            number: itemsAmount + 1,
            product: product.id,
            product_name: product.label,
            cost_category: costCategory.id,
            cost_category_name: costCategory.label,
            amount: productAmount,
            unit_price: unityPrice,
            total_value: productAmount * unityPrice,
          }

          itemsCopy.push(itemInfo);
          setItemsList(itemsCopy);
          setItemsAmount(itemsAmount + 1);
        }

        if (buttonItemLabel === 'Alterar') {
          let itemInfo = {
            number: selectedItemInput.number,
            product: product.id,
            product_name: product.label,
            cost_category: costCategory.id,
            cost_category_name: costCategory.label,
            amount: productAmount,
            unit_price: unityPrice,
            total_value: productAmount * unityPrice,
          };

          let index = itemsCopy.findIndex((element) => element.number === selectedItemInput.number);
          itemsCopy.splice(index, 1, itemInfo);
          setItemsList(itemsCopy);
          setSelectedItemInput(null);
          setButtonItemLabel('Adicionar');
        }

        setProduct({ id: 0, label: '' });
        setCostCategory({ id: 0, label: '' });
        setProductAmount(0);
        setUnityPrice(0);
      } catch (err) {
        console.log(err);
      }
    } else {
      setSnack({
        open: true,
        severity: 'error',
        message: 'Algum campo obrigatório não foi informado.',
      });
    }
  }

  function handleParcelFromList(selectedParcelItem) {
    try {
      if (selectedParcelItem.mode === 'remove') {
        let parcelsCopy = [...parcelsList];
        let index = parcelsCopy.findIndex((element) => element.number === selectedParcelItem.number);

        if (index > -1) {
          let parcelRemoved = parcelsCopy.splice(index, 1);

          if (parcelRemoved && parcelRemoved.length > 0) {
            setParcelsAmount(parcelsAmount - 1);

            setParcelsList(parcelsCopy.map((parcel, index) => ({
              ...parcel,
              number: index + 1,
            })));

            setSelectedParcel(null);
            setButtonParcelLabel('Adicionar');
          }
        }
      }

      if (selectedParcelItem.mode === 'edit') {
        setButtonParcelLabel('Alterar')
        setSelectedParcel(selectedParcelItem);
        setParcelValue(selectedParcelItem.value);
        setExpirationDate(selectedParcelItem.expiration_date);
        setPaymentDate(selectedParcelItem.payment_date);

        if (selectedParcelItem.status === 'Executado') {
          setStatus(statusOptions[1]);
        } else {
          setStatus(statusOptions[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleItemFromList(selectedItemFromList) {
    try {
      if (selectedItemFromList.mode === 'remove') {
        let itemsCopy = [...itemsList];
        let index = itemsCopy.findIndex((element) => element.number === selectedItemFromList.number);

        if (index > -1) {
          let itemRemoved = itemsCopy.splice(index, 1);

          if (itemRemoved && itemRemoved.length > 0) {
            setItemsAmount(itemsAmount - 1);

            setItemsList(itemsCopy.map((item, index) => ({
              ...item,
              number: index + 1,
            })));

            setSelectedItemInput(null);
            setButtonItemLabel('Adicionar');
          }
        }
      }

      if (selectedItemFromList.mode === 'edit') {
        setButtonItemLabel('Alterar');
        setSelectedItemInput(selectedItemFromList);
        setProduct({ id: selectedItemFromList.product, label: selectedItemFromList.product_name });
        setCostCategory({ id: selectedItemFromList.cost_category, label: selectedItemFromList.cost_category_name });
        setProductAmount(selectedItemFromList.amount);
        setUnityPrice(selectedItemFromList.unit_price);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function renderParcelArea() {
    if (paymentType.label === 'Parcelado') {
      return (
        <>
          <Row>
            <h2>Pagamento:</h2>
          </Row>
          <Divider />
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={paymentType}
                  width={230}
                  disabled={false}
                  options={paymentTypesOptions}
                  fontSize={14}
                  label={'Tipo de Pagamento*'}
                  onChange={setPaymentType}
                ></Autocomplete>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Desconto"
                  width={230}
                  disabled={false}
                  value={discount}
                  fontSize={14}
                  onChange={setDiscount}
                ></Numberfield>
              </div>
            </div>
          </Row>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Numberfield
                  label="Valor da Parcela*"
                  width={230}
                  disabled={false}
                  value={parcelValue}
                  fontSize={14}
                  onChange={setParcelValue}
                ></Numberfield>
              </div>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Vencimento da Parcela*"
                  width={230}
                  disabled={false}
                  value={expirationDate}
                  fontSize={14}
                  onChange={setExpirationDate}
                />
              </div>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Pagamento da Parcela"
                  width={230}
                  disabled={false}
                  value={paymentDate}
                  fontSize={14}
                  onChange={setPaymentDate}
                />
              </div>
              <div style={{ width: 253, marginTop: 8 }}>
                <Autocomplete
                  value={status}
                  width={230}
                  disabled={false}
                  options={statusOptions}
                  fontSize={14}
                  label="Status*"
                  onChange={setStatus}
                ></Autocomplete>
              </div>
            </div>
          </Row>
          <Row style={{ justifyContent: 'right', marginBottom: 16 }}>
            <div style={{ display: 'inline-flex', height: 37, paddingRight: 13 }}>
              <Button
                label={buttonParcelLabel}
                background="transparent"
                color="#256CE1"
                borderColor="#256CE1"
                disabled={parcelsSum >= costValue}
                onClick={addOrSaveParcelToList}
              ></Button>
            </div>
          </Row>
          <Table
            columns={columnsParcels}
            rows={parcelsList || []}
            hasEditing={true}
            hasRemoving={true}
            setSelectedItem={handleParcelFromList}
            height="auto"
          ></Table>
          <Row>
            <Explanation>
              Custo total: {formatMoney(costValue)}
              <br></br>
              Somatório das parcelas: <b>{formatMoney(parcelsSum)}</b>
            </Explanation>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Explanation style={{ color: 'var(--red)' }}>
              {costValue !== parcelsSum ? 'O somátório das parcelas deve ser igual ao valor total do insumo!' : ''}
            </Explanation>
          </Row>
        </>
      )
    }
  }

  function renderNonParcelArea() {
    if (paymentType.label !== 'Parcelado') {
      return (
        <>
          <Row>
            <h2>Pagamento:</h2>
          </Row>
          <Divider />
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={paymentType}
                  width={230}
                  disabled={false}
                  options={paymentTypesOptions}
                  fontSize={14}
                  label={'Tipo de Pagamento*'}
                  onChange={setPaymentType}
                ></Autocomplete>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Desconto"
                  width={230}
                  disabled={false}
                  value={discount}
                  fontSize={14}
                  onChange={setDiscount}
                ></Numberfield>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Vencimento*"
                  width={230}
                  disabled={false}
                  value={expirationDate}
                  fontSize={14}
                  onChange={setExpirationDate} />
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Pagamento"
                  width={230}
                  disabled={false}
                  value={paymentDate}
                  fontSize={14}
                  onChange={setPaymentDate} />
              </div>
            </div>
          </Row>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={status}
                  width={230}
                  disabled={false}
                  options={statusOptions}
                  fontSize={14}
                  label="Status*"
                  onChange={setStatus}
                ></Autocomplete>
              </div>
            </div>
          </Row>
        </>
      )
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row><h2>Id do pedido de produto acabado: {finishedProductOrderId}</h2></Row>
          <Divider />
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Compra"
                  width={230}
                  disabled={false}
                  value={purchaseDate}
                  fontSize={14}
                  onChange={setPurchaseDate}
                />
              </div>
              <div style={{ width: 253, marginTop: 8, marginBottom: 8 }}>
                <Autocomplete
                  value={supplier}
                  width={230}
                  disabled={false}
                  options={suppliersOptions}
                  fontSize={14}
                  label="Fornecedor*"
                  onChange={setSupplier}
                ></Autocomplete>
              </div>
            </div>
          </Row>
          <Row>
            <h2>Lista de Itens*:</h2>
          </Row>
          <Divider />
          <Row>
            <Autocomplete
              value={product}
              width={230}
              disabled={false}
              options={productsOptions}
              fontSize={14}
              label={'Insumo*'}
              onChange={setProduct}
            ></Autocomplete>
            <Autocomplete
              value={costCategory}
              width={230}
              disabled={false}
              options={costCategoriesOptions}
              fontSize={14}
              label="Categoria de Custo*"
              onChange={setCostCategory}
            ></Autocomplete>
            <Numberfield
              label="Quantidade*"
              width={230}
              disabled={false}
              value={productAmount}
              fontSize={14}
              onChange={setProductAmount}
            ></Numberfield>
            <Numberfield
              label="Preço Unitário*"
              width={230}
              disabled={false}
              value={unityPrice}
              fontSize={14}
              onChange={setUnityPrice}
            ></Numberfield>
          </Row>
          <Row style={{ justifyContent: 'right', marginBottom: 16 }}>
            <div style={{ display: 'inline-flex', height: 37, paddingRight: 13 }}>
              <Button
                label={buttonItemLabel}
                background="transparent"
                color="#256CE1"
                borderColor="#256CE1"
                disabled={false}
                onClick={addOrSaveItemToList}
              ></Button>
            </div>
          </Row>
          <Table
            columns={columnsItems}
            rows={itemsList || []}
            hasEditing={true}
            hasRemoving={true}
            setSelectedItem={handleItemFromList}
            height="auto"
          ></Table>
          {renderNonParcelArea()}
          {renderParcelArea()}
          <Divider />
          <Row>
            <Textfield
              label="Observações"
              width={'100%'}
              disabled={false}
              value={notes}
              fontSize={14}
              onChange={setNotes}
              multiline={true}
              maxLength={500}
            ></Textfield>
          </Row>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: "right", marginTop: '16px', marginBottom: '16px', paddingRight: '15px' }}>
            <Button
              label="Salvar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={disableSaveButton}
              onClick={saveOrEditCostAndUpdateList}
            ></Button>
            <Button
              label="Limpar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={false}
              onClick={cleanAll}
            ></Button>
          </Row>
          {
            (!selectedItem.finished_product_order_cost) && (
              <TableNestedItemsAndParcels
                columns={columns}
                columnsParcels={columnsParcels}
                columnsItems={columnsItems}
                rows={costsList || []}
                hasEditing={true}
                hasRemoving={true}
                setSelectedItem={handleSelectedCostItem}
                height="auto"
              ></TableNestedItemsAndParcels>
            )
          }
        </Content >
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Custo do Pedido de Produto Acabado</h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}
        {showModalRemoveCost && (
          <ModalRemoveCost
            open={true}
            width={600}
            height={220}
            selectedItem={selectedCostItem}
            setSelectedItem={setSelectedCostItem}
            setShowModalRemoveCost={setShowModalRemoveCost}
            setRefreshDataFromServer={setRefreshDataFromServer}
          />
        )}
        {showModalVisualizeCost && (
          <ModalVisualizeCost
            open={true}
            width={1000}
            height={450}
            selectedItem={selectedCostItem}
            setSelectedItem={setSelectedCostItem}
            setShowModalVisualizeCost={setShowModalVisualizeCost}
            columnsParcels={columnsParcels}
            columnsItems={columnsItems}
          />
        )}
      </Container>
    </Modal>
  )
}