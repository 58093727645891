import { Container, Header, Row, Content, Divider } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import moment from "moment";
import { formatMoney, formatNumber } from "../../../services/functions";
import Table from "../../../components/Table";

export default function ModalVisualizeItemSale({ open, width, height, selectedItem, setSelectedItem, setShowModalVisualizeItemSale, columnsParcels }) {
  const saleItem = selectedItem.item;

  function handleClose() {
    open = false;
    setSelectedItem(null);
    setShowModalVisualizeItemSale(false);
  }

  function renderContent() {
    return (
      <Content>
        <Row>
          <h1 style={{ width: '100%' }}><b>Id da venda:</b> {saleItem.id}</h1>
        </Row>
        <Divider />
        <Row>
          <h4><b>Data da venda:</b> {moment(saleItem.dt_sale).add(3, 'hours').format("DD/MM/YYYY")}</h4>
          <h4><b>Comprador:</b> {saleItem.provider_name}</h4>
          <h4><b>Vendedor:</b> {saleItem.seller_name}</h4>
          <h4><b>Categoria de custo:</b> {saleItem.cost_category_name}</h4>
        </Row>
        <Row>
          <h4><b>Produto acabado:</b> {saleItem.finished_product_order_item_name}</h4>
          <h4><b>Quantidade:</b> {formatNumber(saleItem.amount)}</h4>
          <h4><b>Preço unitário:</b> {formatMoney(saleItem.unit_price)}</h4>
          <h4><b>Valor total:</b> {formatMoney(saleItem.total_value)}</h4>
        </Row>
        {(saleItem.payment_type_name === 'Parcelado') && (
          <Row>
            <h4><b>Tipo de pagamento:</b> {saleItem.payment_type_name}</h4>
            <h4><b>Desconto:</b> {formatMoney(saleItem.discount)}</h4>
            <h4> </h4>
            <h4> </h4>
          </Row>
        )}
        {(saleItem.payment_type_name !== 'Parcelado') && (
          <>
            <Row>
              <h4><b>Tipo de pagamento:</b> {saleItem.payment_type_name}</h4>
              <h4><b>Desconto:</b> {formatMoney(saleItem.discount)}</h4>
              <h4><b>Data de vencimento:</b> {moment(saleItem.parcels[0].expiration_date).add(3, 'hours').format("DD/MM/YYYY")}</h4>
              <h4><b>Data de pagamento:</b> {saleItem.parcels[0].payment_date ? moment(saleItem.parcels[0].payment_date).add(3, 'hours').format("DD/MM/YYYY") : '-'}</h4>
            </Row>
            <Row>
              <h4><b>Status:</b> {saleItem.parcels[0].status}</h4>
              <h4> </h4>
              <h4> </h4>
              <h4> </h4>
            </Row>
          </>
        )}
        <Row><h4><b>Observações:</b> {saleItem.notes}</h4></Row>
        <Row></Row>
        {(saleItem.payment_type_name === 'Parcelado') && (
          <>
            <Row><h4><b>Parcelas:</b></h4></Row>
            <Table
              columns={columnsParcels}
              rows={saleItem.parcels || []}
              hasEditing={false}
              hasRemoving={false}
              height="auto"
            ></Table>
          </>
        )}
      </Content>
    )
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Detalhes da Venda de Produto Acabado</h1>
          <img src={Close} onClick={handleClose} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}