import { useEffect, useState, useContext } from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import { Container, Header, Row, Content } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import AppContext from "../../../../state/App.context";
import { UseBeneficiationCompanies } from "../../../../hooks/useBeneficiationCompanies";

export default function ModalEditBeneficiationCompany({ open, setSelectedItem, width, height, selectedItem, updateRegistersList }) {
  const { updateBeneficiationCompany } = UseBeneficiationCompanies();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [, setSnack] = useContext(AppContext).snackState;

  function handleClose() {
    setSelectedItem({ open: false, mode: '', name: '', id: null });
  }

  function getInfo() {
    try {
      setName(selectedItem?.name);
      setDescription(selectedItem?.description);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open) getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const title = 'Editar produto acabado';

  function checkingRequiredFields() {
    if (!name) {
      setSnack({
        open: true,
        severity: 'error',
        message: 'Preencha todos os campos necessários!',
      });

      return false;
    }

    return true;
  }

  async function handleEditBeneficiationCompany(event) {
    try {
      setLoading(true);
      event.preventDefault();

      if (checkingRequiredFields()) {
        const response = await updateBeneficiationCompany(selectedItem.id, 
          {
            name: name,
            description,
          }
        );

        if (response.success) {
          handleClose();
          updateRegistersList(true);
          
          setSnack({
            open: true,
            severity: 'success',
            message: response?.message,
          })
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: 'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
          })
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row>
            <Textfield
              label="Nome da empresa de produto acabado*"
              width={600}
              disabled={false}
              value={name}
              fontSize={14}
              onChange={setName}
              multiline={false}
              maxLength={60}
            ></Textfield>
          </Row>
          <Row>
            <Textfield
              label="Descrição"
              width={600}
              disabled={false}
              value={description}
              fontSize={14}
              onChange={setDescription}
              multiline={false}
              maxLength={300}
            ></Textfield>
          </Row>
          <Row>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={() => handleClose()}
            ></Button>
            <Button
              label="Salvar alterações"
              background="#256CE1"
              color="white"
              borderColor="#256CE1"
              disabled={false}
              onClick={handleEditBeneficiationCompany}
            ></Button>
          </Row>
        </Content>
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}