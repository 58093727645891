import { useEffect, useState, useContext } from "react";
import { Container, Header, Row, Content, ListTitle, Divider } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import { UseTrips } from "../../../../hooks/useTrips";
import { UseEmployees } from "../../../../hooks/useEmployees";
import AppContext from "../../../../state/App.context";
import moment from "moment";
import Table from "../../../../components/Table";

const columns = [
  {
    name: 'Funcionário',
    key: 'label',
    type: 'string',
    unit: '',
  },
]


export default function ModalTripDetails({ open, setSelectedItem, width, height, selectedItem }) {
  const { trips } = UseTrips();
  const { getTripEmployees } = UseEmployees();
  const [trip, setTrip] = useState({});
  const [employeesList, setEmployeesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const title = 'Detalhes da viagem';

  function handleClose() {
    setSelectedItem({ open: false, mode: '', trips_name: '', id_trip: null });
  }

  async function getEmployessFromTrip() {
    try {
      const responseEmployees = await getTripEmployees(selectedItem?.item?.id_trip);
      if (responseEmployees.success) {
        let formattedEmployees = [];
        responseEmployees?.data.map((employee) => (
          formattedEmployees.push({
            id: employee?.id_employee,
            label: employee?.full_name,
          })
        ))
        setEmployeesList(formattedEmployees)
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Erro:' + responseEmployees?.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }


  function getInfo() {
    try {
      getEmployessFromTrip();
      const selectedTrip = trips.filter((element) => element.id_trip === selectedItem?.item?.id_trip)[0];
      setTrip(selectedTrip);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open) getInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row>
            <h4><b>Nome da viagem:</b> {trip?.trips_name}</h4>
            <h4><b>Barco:</b> {trip?.boats_name}</h4>
            <h4><b>Safra:</b> {trip?.harvest}</h4>
          </Row>
          <Row>
            <h4><b>Espécie Alvo:</b> {trip?.fishingtypes_name}</h4>
            <h4><b>Produção(kg):</b> {trip?.production}</h4>
          </Row>
          <Row>
            <h4><b>Saída:</b> {trip?.begin_date ? moment(trip.begin_date).add(3, 'hours').format('DD/MM/YYYY [às] HH:mm') : ''}</h4>
            <h4><b>Chegada:</b> {trip?.end_date ? moment(trip.end_date).add(3, 'hours').format('DD/MM/YYYY [às] HH:mm') : ''}</h4>
            <h4><b>Consumo de óleo motor:</b> {trip?.oil_consumption_engine_1}</h4>
          </Row>
          <Row style={{ gridTemplateColumns: '1fr' }}>
            <h4 ><b>Observações:</b> {trip?.notes}</h4>
          </Row>
          <Row>
            <ListTitle>Lista de tripulantes:</ListTitle>
          </Row>
          <Divider></Divider>
          <Table
            columns={columns}
            rows={employeesList || []}
            hasEditing={false}
            hasRemoving={false}
            setSelectedItem={() => { }}
            height="auto"
          ></Table>

        </Content>
      )

    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={() => handleClose(false)} alt="Fechar"></img>
        </Header>
        {renderContent()}

      </Container>
    </Modal>
  )
}