import { useState, useContext, useEffect } from "react";
import Button from "../../../components/Button";
import Numberfield from "../../../components/Numberfield";
import { RawDatepicker } from "../../../components/Datepicker/index";
import { Container, Header, Row, Content, Divider, Explanation } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseProducts } from "../../../hooks/useProducts";
import Autocomplete from "../../../components/Autocomplete";
import Textfield from "../../../components/Textfield";
import AppContext from "../../../state/App.context";
import Table from '../../../components/Table';
import { UseFinishedProductOrders } from "../../../hooks/useFinishedProductOrders";
import { UseOrders } from "../../../hooks/useOrders";
import { UseBeneficiationCompanies } from "../../../hooks/useBeneficiationCompanies";
import ModalRemoveBeneficiationItem from "../ModalRemoveBeneficiationItem";
import ModalVisualizeBeneficiationItem from "../ModalVisualizeBeneficiationItem";
import { UsePaymentTypes } from "../../../hooks/usePaymentTypes";
import moment from "moment";
import { formatMoney } from "../../../services/functions";
import TableNestedItemsAndParcels from "../../../components/TableNestedItemsAndParcels";

const columns = [
  {
    name: 'Data de Beneficiamento',
    key: 'purchase_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Empresa de Beneficiamento',
    key: 'beneficiation_company_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Lote',
    key: 'batch',
    type: 'string',
    unit: '',
  },
  {
    name: 'Desconto',
    key: 'discount',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Tipo de Pagamento',
    key: 'payment_type_name',
    type: 'string',
    unit: '',
  },
]

const columnsParcels = [
  {
    name: 'Nº',
    key: 'number',
    type: 'number',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Vencimento',
    key: 'expiration_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Pagamento',
    key: 'payment_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'string',
    unit: '',
  },

]

const statusOptions = [
  {
    id: 0,
    label: 'Pendente'
  },
  {
    id: 1,
    label: 'Executado',
  }
]

const columnsItems = [
  {
    name: 'Matéria-prima',
    key: 'raw_material_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade de Matéria-prima',
    key: 'raw_material_amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Peixe Fraco',
    key: 'weakfish',
    type: 'number',
    unit: '',
  },
  {
    name: 'Produto Acabado',
    key: 'finished_product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade Produzida',
    key: 'produced_amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Custo do Beneficiamento',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
]

export default function ModalManageBeneficiationItems({ open, width, height, selectedItem, setSelectedItem, setRefreshData }) {
  const { products } = UseProducts();
  const { getFinishedProductOrderBeneficiationItems, createFinishedProductOrderBeneficiationItem,
    updateFinishedProductOrderBeneficiationItem } = UseFinishedProductOrders();
  const { getOrderTransactions } = UseOrders();
  const { getBeneficiationCompanies } = UseBeneficiationCompanies();
  const { paymentTypes } = UsePaymentTypes();

  const [transactions, setTransactions] = useState([]);

  const [beneficiationCompany, setBeneficiationCompany] = useState({ id: 0, label: '' });
  const [beneficiationCompanyOptions, setBeneficiationCompanyOptions] = useState([]);
  const [batch, setBatch] = useState('');
  const [paymentType, setPaymentType] = useState({ id: 0, label: '' });
  const [discount, setDiscount] = useState(0);
  const [notes, setNotes] = useState('');
  const [purchaseDate, setPurchaseDate] = useState(moment().format('YYYY-MM-DD'));

  const [rawMaterial, setRawMaterial] = useState({ id: 0, label: '', amount: 0 });
  const [rawMaterialAmount, setRawMaterialAmount] = useState(0);
  const [finishedProduct, setFinishedProduct] = useState({ id: 0, label: '' });
  const [producedAmount, setProducedAmount] = useState(0);
  const [weakFish, setWeakFish] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);

  const [parcelValue, setParcelValue] = useState(0);
  const [expirationDate, setExpirationDate] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [status, setStatus] = useState(statusOptions[0]);

  const [selectedParcel, setSelectedParcel] = useState(null);
  const [buttonParcelLabel, setButtonParcelLabel] = useState('Adicionar');
  const [parcelsAmount, setParcelsAmount] = useState(0);
  const [parcelsList, setParcelsList] = useState([]);

  const [itemsList, setItemsList] = useState([]);
  const [buttonItemLabel, setButtonItemLabel] = useState('Adicionar');
  const [itemsAmount, setItemsAmount] = useState(0);
  const [selectedItemInput, setSelectedItemInput] = useState(null);

  const [beneficiationItemsList, setBeneficiationItemsList] = useState([]);

  const [selectedBeneficiationItem, setSelectedBeneficiationItem] = useState(null);

  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;

  const [showModalRemoveBeneficiationItem, setShowModalRemoveBeneficiationItem] = useState(false);
  const [showModalVisualizeBeneficiationItem, setShowModalVisualizeBeneficiationItem] = useState(false);

  const [refreshDataFromServer, setRefreshDataFromServer] = useState(0);

  const [finishedProductOrderId, setFinishedProductOrderId] = useState('');

  const [beneficiationToEdit, setBeneficiationToEdit] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let id_finished_product_order = '';
      let id_order = '';

      if (selectedItem.finished_product_order_beneficiation && selectedItem.finished_product_order_input_order && selectedItem.finished_product_order) {
        //finantial schedule screen
        id_finished_product_order = selectedItem.finished_product_order;
        id_order = selectedItem.finished_product_order_input_order;
      } else {
        //modal finished product order modal
        id_finished_product_order = selectedItem.item?.id_finished_product_order;
        id_order = selectedItem.item?.id_order;
      }

      setFinishedProductOrderId(id_finished_product_order);

      try {
        // Carrega produtos
        let responseTransactions = await getOrderTransactions(id_order);

        if (responseTransactions.success) {
          const activeTransactions = responseTransactions?.data.filter(element => element.isactive);
          setTransactions(activeTransactions);

          // Carrega empresas beneficiadoras (dependente do estado anterior)
          let responseBeneficiationCompanies = await getBeneficiationCompanies();

          if (responseBeneficiationCompanies.success) {
            const companies = responseBeneficiationCompanies?.data.map(beneficiationCompany => ({
              id: beneficiationCompany.id,
              label: beneficiationCompany.name,
            }));

            setBeneficiationCompanyOptions(companies);

            // Carrega itens do pedido (dependente dos dois anteriores)
            let responseBeneficiationItems = await getFinishedProductOrderBeneficiationItems(id_finished_product_order);

            if (responseBeneficiationItems.success) {
              const items = [];

              responseBeneficiationItems?.data.forEach(beneficiationItem => {
                const beneficiationFormatted = {
                  id: beneficiationItem.id,
                  finished_product_order: beneficiationItem.finished_product_order,
                  beneficiation_company: beneficiationItem.beneficiation_company,
                  beneficiation_company_name: companies.find((company) => company.id === beneficiationItem.beneficiation_company)?.label,
                  batch: beneficiationItem.batch,
                  discount: beneficiationItem.discount,
                  notes: beneficiationItem.notes,
                  payment_type: beneficiationItem.payment_type,
                  payment_type_name: paymentTypes.find((paymentType) => paymentType.id_paymenttype === beneficiationItem.payment_type).paymenttype_name,
                  total_value: beneficiationItem.items?.reduce((total, item) => total + ((item.raw_material_amount - item.weakfish) * item.unit_price), 0) - beneficiationItem.discount,
                  parcels: beneficiationItem.parcels?.map((parcel) => ({
                    number: parcel.number,
                    value: parcel.value,
                    expiration_date: parcel.expiration_date,
                    payment_date: parcel.payment_date,
                    status: parcel.status,
                  })),
                  items: beneficiationItem.items?.map((item, index) => ({
                    number: index + 1,
                    id: item.id,
                    raw_material_transaction: item.raw_material_transaction,
                    raw_material_name: activeTransactions.find((transaction) => transaction.id_transaction === item.raw_material_transaction)?.product_name,
                    raw_material_amount: item.raw_material_amount ?? 0,
                    finished_product: item.finished_product,
                    finished_product_name: products.find((product) => product.id_product === item.finished_product)?.product_name,
                    produced_amount: item.produced_amount,
                    unit_price: item.unit_price,
                    total_value: (item.raw_material_amount - item.weakfish) * item.unit_price,
                    weakfish: item.weakfish,
                    withdrawn_amount: item.withdrawn_amount,
                  })),
                  purchase_date: beneficiationItem.purchase_date,
                };

                if (selectedItem.finished_product_order_beneficiation && (selectedItem.finished_product_order_beneficiation === beneficiationItem.id)) {
                  setBeneficiationToEdit({ ...beneficiationFormatted });
                }

                items.push(beneficiationFormatted);
              });

              setBeneficiationItemsList(items);
            } else {
              setSnack({
                open: true,
                severity: 'error',
                message: responseBeneficiationItems?.message,
              });
            }
          } else {
            setSnack({
              open: true,
              severity: 'error',
              message: responseBeneficiationCompanies?.message,
            });
          }
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: responseTransactions?.message,
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDataFromServer]);

  function cleanAllInputs() {
    setFinishedProduct({ id: 0, label: '' });
    setProducedAmount(0);
    setRawMaterialAmount(0);
    setRawMaterial({ id: 0, label: '', amount: 0 });
    setUnitPrice(0);
    setBeneficiationCompany({ id: 0, label: '' });
    setBatch('');
    setWeakFish(0);
    setPaymentType({ id: 0, label: '' });
    setDiscount(0);
    setNotes('');
    setParcelValue(0);
    setExpirationDate('');
    setPaymentDate('');
    setStatus(statusOptions[0]);
    setParcelsAmount(0);
    setItemsAmount(0);
    setParcelsList([]);
    setItemsList([]);
    setButtonParcelLabel('Adicionar');
    setButtonItemLabel('Adicionar');
    setSelectedParcel(null);
    setSelectedItemInput(null);
    setPurchaseDate(moment().format('YYYY-MM-DD'));
  }

  function cleanAll() {
    cleanAllInputs();
    setSelectedBeneficiationItem(null);
  }

  let finishedProductsToShow = products && !products.error && products.filter(a => a.type === 'Produto Acabado');

  let finishedProductsOptions = [];

  finishedProductsToShow && finishedProductsToShow.forEach((element) => {
    if (element.isactive)
      finishedProductsOptions.push({
        id: element?.id_product,
        label: element?.product_name,
      });
  })

  finishedProductsOptions.sort((a, b) => {
    const labelA = a.label.trim().toUpperCase();
    const labelB = b.label.trim().toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

  let rawMaterialOptions = transactions && transactions.map((transaction) => {
    return {
      id: transaction.id_transaction,
      label: transaction.product_name,
      amount: transaction.product_amount,
    }
  });

  let paymentTypesOptions = paymentTypes && !paymentTypes.error && paymentTypes.map((element) => {
    return {
      id: element?.id_paymenttype,
      label: element?.paymenttype_name,
    }
  });

  let parcelsSum = 0;

  for (let i = 0; i < parcelsList.length; i++) {
    if (parcelsList[i]?.value !== undefined && !isNaN(parcelsList[i]?.value)) {
      if (buttonParcelLabel === 'Adicionar') {
        parcelsSum += parcelsList[i]?.value;
      } else if (buttonParcelLabel === 'Alterar') {
        if (parcelsList[i]?.number !== selectedParcel.number) {
          parcelsSum += parcelsList[i]?.value;
        }
      }
    }
  }

  let sumTotalValueItems = 0;

  for (let i = 0; i < itemsList.length; i++) {
    if (buttonItemLabel === 'Adicionar') {
      sumTotalValueItems += itemsList[i]?.total_value;
    } else if (buttonItemLabel === 'Alterar') {
      if (itemsList[i]?.id !== selectedItemInput.id) {
        sumTotalValueItems += itemsList[i]?.total_value;
      }
    }
  }

  let beneficiationItemValue = sumTotalValueItems - discount;
  let netFish = rawMaterialAmount - weakFish;

  if (beneficiationToEdit) {
    handleSelectedBeneficiationItem({
      open: true,
      mode: "edit",
      ...beneficiationToEdit,
    });

    setBeneficiationToEdit(null);
  }

  async function saveOrEditBeneficiationItemAndUpdateList(event) {
    try {
      setLoading(true);
      setDisableSaveButton(true);
      event.preventDefault();

      if ((itemsList.length > 0) && beneficiationCompany.id &&
        paymentType && paymentType.id && (discount >= 0) && status && status.label &&
        purchaseDate && moment(purchaseDate).isValid()) {
        if (((paymentType.label !== 'Parcelado') && (moment(expirationDate).isValid())) ||
          ((paymentType.label === 'Parcelado') && (parcelsSum === beneficiationItemValue))) {
          let beneficiation_item_id = null;

          if (selectedBeneficiationItem && selectedBeneficiationItem.id) {
            beneficiation_item_id = selectedBeneficiationItem.id;
          }

          let parcelsListAux = null;

          if (paymentType.label !== 'Parcelado') {
            parcelsListAux = [{
              number: 1,
              value: beneficiationItemValue,
              expiration_date: moment(expirationDate).isValid() ? expirationDate : null,
              payment_date: moment(paymentDate).isValid() ? paymentDate : null,
              status: status.label,
            }];

            setParcelsList([{
              ...parcelsListAux[0],
            }]);
          } else {
            parcelsListAux = parcelsList.map((parcel, index) => ({
              number: index + 1,
              value: parcel.value,
              expiration_date: moment(parcel.expiration_date).isValid() ? parcel.expiration_date : null,
              payment_date: moment(parcel.payment_date).isValid() ? parcel.payment_date : null,
              status: parcel.status,
            }));
          }

          const itemsListAux = itemsList.map((item) => ({
            id: item.id,
            raw_material_transaction: item.raw_material_transaction,
            raw_material_amount: item.raw_material_amount ?? 0,
            finished_product: item.finished_product,
            produced_amount: item.produced_amount,
            unit_price: item.unit_price,
            weakfish: item.weakfish,
            withdrawn_amount: item.withdrawn_amount,
          }));

          if (beneficiation_item_id) {
            let result = await updateFinishedProductOrderBeneficiationItem(beneficiation_item_id, {
              beneficiation_company: beneficiationCompany.id,
              batch: batch,
              payment_type: paymentType.id,
              discount: discount,
              notes: notes,
              parcels: parcelsListAux,
              items: itemsListAux,
              purchase_date: purchaseDate,
            });

            if (result.success) {
              setSnack({
                open: true,
                severity: 'success',
                message: 'Item de beneficiamento de pedido de produto acabado atualizado com sucesso!',
              });

              if (selectedItem.finished_product_order_beneficiation) {
                handleClose();
              } else {
                setRefreshDataFromServer((prevValue) => prevValue + 1);
                setSelectedBeneficiationItem(null);
                cleanAllInputs();
              }
            } else {
              setSnack({
                open: true,
                severity: 'error',
                message: 'Não foi possível atualizar o cadastro do item de beneficiamento de pedido de produto acabado: ' + result.message,
              });
            }
          } else {
            let result = await createFinishedProductOrderBeneficiationItem({
              finished_product_order: finishedProductOrderId,
              beneficiation_company: beneficiationCompany.id,
              batch: batch,
              payment_type: paymentType.id,
              discount: discount,
              notes: notes,
              parcels: parcelsListAux,
              items: itemsListAux,
              purchase_date: purchaseDate,
            });

            if (result.success) {
              setRefreshDataFromServer((prevValue) => prevValue + 1);
              setSelectedBeneficiationItem(null);
              cleanAllInputs();

              setSnack({
                open: true,
                severity: 'success',
                message: 'Item de beneficiamento de pedido de produto acabado cadastrado com sucesso!',
              });
            } else {
              setSnack({
                open: true,
                severity: 'error',
                message: 'Não foi possível realizar o cadastro do item de beneficiamento de pedido de produto acabado: ' + result.message,
              });
            }
          }
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: 'Data de vencimento não informada ou a soma das parcelas deve corresponder ao valor total dos itens beneficiados!',
          });
        }
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Preencha todos os campos necessários!',
        });
      }
    } catch (err) {
      console.log(err);
      setSnack({
        open: true,
        severity: 'error',
        message: err,
      });
    } finally {
      setLoading(false);
      setDisableSaveButton(false);
    }
  }

  function handleSelectedBeneficiationItem(item) {
    setSelectedBeneficiationItem(item);

    if (item.mode === 'edit') {
      setBeneficiationCompany({ id: item.beneficiation_company, label: item.beneficiation_company_name });
      setBatch(item.batch);
      setPaymentType({ id: item.payment_type, label: item.payment_type_name });
      setDiscount(item.discount);
      setNotes(item.notes);

      if (item.payment_type_name !== 'Parcelado') {
        setExpirationDate(item.parcels[0].expiration_date);
        setPaymentDate(item.parcels[0].payment_date);

        if (item.parcels[0].status === 'Executado') {
          setStatus(statusOptions[1]);
        } else {
          setStatus(statusOptions[0]);
        }
      } else {
        setExpirationDate('');
        setPaymentDate('');
        setStatus(statusOptions[0]);
      }

      setParcelsList(item.parcels);
      setParcelsAmount(item.parcels.length);
      setItemsList(item.items);
      setItemsAmount(item.items.length);
      setPurchaseDate(item.purchase_date);
    }

    if (item.mode === 'remove') {
      if (item.items?.filter((item) => (item.withdrawn_amount !== 0) && (item.withdrawn_amount !== null))?.length === 0) {
        setShowModalRemoveBeneficiationItem(true);
        cleanAllInputs();
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'O item selecionado possui venda associada e não poderá ser removido.',
        });
      }
    }

    if (item.mode === 'visualize') {
      setShowModalVisualizeBeneficiationItem(true);
    }
  }

  function handleClose() {
    cleanAll();

    if (selectedItem.finished_product_order_beneficiation) {
      setSelectedItem({ open: false, mode: '', name: '', id_order: null });
      setRefreshData(prevIsRefreshData => !prevIsRefreshData);
    } else {
      setSelectedItem({ open: false, mode: 'view', item: null });
    }
  }

  function addOrSaveParcelToList() {
    if ((parcelValue > 0) && expirationDate && moment(expirationDate).isValid() &&
      status && status.label) {
      if (parcelValue + parcelsSum <= beneficiationItemValue) {
        try {
          let parcelsCopy = [...parcelsList];

          if (buttonParcelLabel === 'Adicionar') {
            let parcelInfo = {
              number: parcelsAmount + 1,
              value: parcelValue,
              expiration_date: expirationDate,
              payment_date: paymentDate,
              status: status.label,
            }

            parcelsCopy.push(parcelInfo);
            setParcelsList(parcelsCopy);
            setParcelsAmount(parcelsAmount + 1);
          }

          if (buttonParcelLabel === 'Alterar') {
            let parcelInfo = {
              ...selectedParcel,
              value: parcelValue,
              expiration_date: expirationDate,
              payment_date: paymentDate,
              status: status.label,
            };

            let index = parcelsCopy.findIndex((element) => element.number === selectedParcel.number);
            parcelsCopy.splice(index, 1, parcelInfo);
            setParcelsList(parcelsCopy);
            setSelectedParcel(null);
            setButtonParcelLabel('Adicionar');
          }

          setParcelValue(0);
          setExpirationDate('');
          setPaymentDate('');
          setStatus(statusOptions[0]);
        } catch (err) {
          console.log(err);
        }
      } else {
        setParcelValue(beneficiationItemValue - parcelsSum);

        setSnack({
          open: true,
          severity: 'error',
          message: 'O somatório das parcelas será maior do que o valor dos itens de beneficiamento. O valor da parcela foi atualizado para o máximo permitido.',
        });
      }
    } else {
      setSnack({
        open: true,
        severity: 'error',
        message: 'O valor e a data de vencimento devem ser informados.',
      });
    }
  }

  function addOrSaveItemToList() {
    if (rawMaterial.label && rawMaterialAmount &&
      finishedProduct.label && producedAmount && (unitPrice > 0)) {
      if (rawMaterialAmount >= producedAmount + weakFish) {
        if (rawMaterialAmount <= (selectedItemInput ? (rawMaterial.amount + selectedItemInput.raw_material_amount) : rawMaterial.amount)) {
          try {
            let itemsCopy = [...itemsList];

            if (buttonItemLabel === 'Adicionar') {
              let itemInfo = {
                number: itemsAmount + 1,
                id: null,
                raw_material_transaction: rawMaterial.id,
                raw_material_name: rawMaterial.label,
                raw_material_amount: rawMaterialAmount,
                finished_product: finishedProduct.id,
                finished_product_name: finishedProduct.label,
                produced_amount: producedAmount,
                unit_price: unitPrice,
                total_value: (rawMaterialAmount - weakFish) * unitPrice,
                weakfish: weakFish,
              }

              itemsCopy.push(itemInfo);
              setItemsList(itemsCopy);
              setItemsAmount(itemsAmount + 1);
            }

            if (buttonItemLabel === 'Alterar') {
              let itemInfo = {
                number: selectedItemInput.number,
                id: selectedItemInput.id,
                raw_material_transaction: rawMaterial.id,
                raw_material_name: rawMaterial.label,
                raw_material_amount: rawMaterialAmount,
                finished_product: finishedProduct.id,
                finished_product_name: finishedProduct.label,
                produced_amount: producedAmount,
                unit_price: unitPrice,
                total_value: (rawMaterialAmount - weakFish) * unitPrice,
                weakfish: weakFish,
              };

              let index = itemsCopy.findIndex((element) => element.number === selectedItemInput.number);
              itemsCopy.splice(index, 1, itemInfo);
              setItemsList(itemsCopy);
              setSelectedItemInput(null);
              setButtonItemLabel('Adicionar');
            }

            setRawMaterial({ id: 0, label: '' });
            setRawMaterialAmount(0);
            setFinishedProduct({ id: 0, label: '' });
            setProducedAmount(0);
            setWeakFish(0);
            setUnitPrice(0);
          } catch (err) {
            console.log(err);
          }
        } else {
          setRawMaterialAmount(selectedItemInput ? (rawMaterial.amount + selectedItemInput.raw_material_amount) : rawMaterial.amount);

          setSnack({
            open: true,
            severity: 'error',
            message: 'A quantidade de matéria-prima deve ser menor ou igual à quantidade disponível.',
          });
        }
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'A quantidade de matéria-prima deve ser maior ou igual à soma da quantidade produzida e do peixe fraco.',
        });
      }
    } else {
      setSnack({
        open: true,
        severity: 'error',
        message: 'Algum campo obrigatório não foi informado.',
      });
    }
  }

  function handleParcelFromList(selectedParcelItem) {
    try {
      if (selectedParcelItem.mode === 'remove') {
        let parcelsCopy = [...parcelsList];
        let index = parcelsCopy.findIndex((element) => element.number === selectedParcelItem.number);

        if (index > -1) {
          let parcelRemoved = parcelsCopy.splice(index, 1);

          if (parcelRemoved && parcelRemoved.length > 0) {
            setParcelsAmount(parcelsAmount - 1);

            setParcelsList(parcelsCopy.map((parcel, index) => ({
              ...parcel,
              number: index + 1,
            })));

            setSelectedParcel(null);
            setButtonParcelLabel('Adicionar');
          }
        }
      }

      if (selectedParcelItem.mode === 'edit') {
        setButtonParcelLabel('Alterar')
        setSelectedParcel(selectedParcelItem);
        setParcelValue(selectedParcelItem.value);
        setExpirationDate(selectedParcelItem.expiration_date);
        setPaymentDate(selectedParcelItem.payment_date);

        if (selectedParcelItem.status === 'Executado') {
          setStatus(statusOptions[1]);
        } else {
          setStatus(statusOptions[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleItemFromList(selectedItemFromList) {
    try {
      if (selectedItemFromList.mode === 'remove') {
        if (selectedItemFromList.withdrawn_amount === 0) {
          let itemsCopy = [...itemsList];
          let index = itemsCopy.findIndex((element) => element.number === selectedItemFromList.number);

          if (index > -1) {
            let itemRemoved = itemsCopy.splice(index, 1);

            if (itemRemoved && itemRemoved.length > 0) {
              setItemsAmount(itemsAmount - 1);

              setItemsList(itemsCopy.map((item, index) => ({
                ...item,
                number: index + 1,
              })));

              setSelectedItemInput(null);
              setButtonItemLabel('Adicionar');
            }
          }
        } else {
          setSelectedItemInput(null);

          setSnack({
            open: true,
            severity: 'error',
            message: 'O item selecionado possui venda associada e não poderá ser removido.',
          });
        }
      }

      if (selectedItemFromList.mode === 'edit') {
        setButtonItemLabel('Alterar');
        setSelectedItemInput(selectedItemFromList);
        setFinishedProduct({ id: selectedItemFromList.finished_product, label: selectedItemFromList.finished_product_name });
        setProducedAmount(selectedItemFromList.produced_amount);
        setRawMaterialAmount(selectedItemFromList.raw_material_amount);
        setRawMaterial({ id: selectedItemFromList.raw_material_transaction, label: selectedItemFromList.raw_material_name, amount: transactions.find((transaction) => transaction.id_transaction === selectedItemFromList.raw_material_transaction)?.product_amount });
        setUnitPrice(selectedItemFromList.unit_price);
        setWeakFish(selectedItemFromList.weakfish);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function renderParcelArea() {
    if (paymentType.label === 'Parcelado') {
      return (
        <>
          <Row>
            <h2>Pagamento:</h2>
          </Row>
          <Divider />
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={paymentType}
                  width={230}
                  disabled={false}
                  options={paymentTypesOptions}
                  fontSize={14}
                  label={'Tipo de Pagamento*'}
                  onChange={setPaymentType}
                ></Autocomplete>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Desconto"
                  width={230}
                  disabled={false}
                  value={discount}
                  fontSize={14}
                  onChange={setDiscount}
                ></Numberfield>
              </div>
            </div>
          </Row>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Numberfield
                  label="Valor da Parcela*"
                  width={230}
                  disabled={false}
                  value={parcelValue}
                  fontSize={14}
                  onChange={setParcelValue}
                ></Numberfield>
              </div>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Vencimento da Parcela*"
                  width={230}
                  disabled={false}
                  value={expirationDate}
                  fontSize={14}
                  onChange={setExpirationDate}
                />
              </div>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Pagamento da Parcela"
                  width={230}
                  disabled={false}
                  value={paymentDate}
                  fontSize={14}
                  onChange={setPaymentDate}
                />
              </div>
              <div style={{ width: 253, marginTop: 8 }}>
                <Autocomplete
                  value={status}
                  width={230}
                  disabled={false}
                  options={statusOptions}
                  fontSize={14}
                  label="Status*"
                  onChange={setStatus}
                ></Autocomplete>
              </div>
            </div>
          </Row>
          <Row style={{ justifyContent: 'right', marginBottom: 16 }}>
            <div style={{ display: 'inline-flex', height: 37, paddingRight: 13 }}>
              <Button
                label={buttonParcelLabel}
                background="transparent"
                color="#256CE1"
                borderColor="#256CE1"
                disabled={parcelsSum >= beneficiationItemValue}
                onClick={addOrSaveParcelToList}
              ></Button>
            </div>
          </Row>
          <Table
            columns={columnsParcels}
            rows={parcelsList || []}
            hasEditing={true}
            hasRemoving={true}
            setSelectedItem={handleParcelFromList}
            height="auto"
          ></Table>
          <Row>
            <Explanation>
              Total do beneficiamento com desconto:: {formatMoney(beneficiationItemValue)}
              <br></br>
              Somatório das parcelas: <b>{formatMoney(parcelsSum)}</b>
            </Explanation>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Explanation style={{ color: 'var(--red)' }}>
              {beneficiationItemValue !== parcelsSum ? 'O somátório das parcelas deve ser igual ao valor total do beneficiamento com desconto!' : ''}
            </Explanation>
          </Row>
        </>
      )
    }
  }

  function renderNonParcelArea() {
    if (paymentType.label !== 'Parcelado') {
      return (
        <>
          <Row>
            <h2>Pagamento:</h2>
          </Row>
          <Divider />
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={paymentType}
                  width={230}
                  disabled={false}
                  options={paymentTypesOptions}
                  fontSize={14}
                  label={'Tipo de Pagamento*'}
                  onChange={setPaymentType}
                ></Autocomplete>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Desconto"
                  width={230}
                  disabled={false}
                  value={discount}
                  fontSize={14}
                  onChange={setDiscount}
                ></Numberfield>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Vencimento*"
                  width={230}
                  disabled={false}
                  value={expirationDate}
                  fontSize={14}
                  onChange={setExpirationDate} />
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Pagamento"
                  width={230}
                  disabled={false}
                  value={paymentDate}
                  fontSize={14}
                  onChange={setPaymentDate} />
              </div>
            </div>
          </Row>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={status}
                  width={230}
                  disabled={false}
                  options={statusOptions}
                  fontSize={14}
                  label="Status*"
                  onChange={setStatus}
                ></Autocomplete>
              </div>
            </div>
          </Row>
        </>
      )
    }
  }

  const getTotalAmountRawMaterialInBeneficiationItemsList = (rawMaterialId) => {
    let total = 0;

    for (const bItem of beneficiationItemsList) {
      if ((selectedBeneficiationItem === null) || ((selectedBeneficiationItem != null) && (selectedBeneficiationItem.id !== bItem.id))) {
        total += bItem.items?.filter((item) => item.raw_material_transaction === rawMaterialId)?.reduce((acumulator, itemReduced) => acumulator + itemReduced.raw_material_amount, 0) ?? 0;
      }
    }

    return total;
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row><h2>Id do pedido de produto acabado: {finishedProductOrderId}</h2></Row>
          <Divider />
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Beneficiamento"
                  width={230}
                  disabled={false}
                  value={purchaseDate}
                  fontSize={14}
                  onChange={setPurchaseDate}
                />
              </div>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={beneficiationCompany}
                  width={230}
                  disabled={false}
                  options={beneficiationCompanyOptions}
                  fontSize={14}
                  label="Empresa de Beneficiamento*"
                  onChange={setBeneficiationCompany}
                ></Autocomplete>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Textfield
                  label="Lote"
                  width={230}
                  disabled={false}
                  value={batch}
                  fontSize={14}
                  onChange={setBatch}
                  multiline={false}
                  maxLength={20}
                ></Textfield>
              </div>
            </div>
          </Row>
          <Row>
            <h2>Lista de Itens*:</h2>
          </Row>
          <Divider />
          <Row>
            <Autocomplete
              value={rawMaterial}
              width={230}
              disabled={false}
              options={rawMaterialOptions}
              fontSize={14}
              label={'Matéria-prima*'}
              onChange={(value) => {
                const rawMaterialInBeneficiationItemsList = getTotalAmountRawMaterialInBeneficiationItemsList(value.id);
                const rawMaterialInItemsList = itemsList?.filter((item) => item.raw_material_transaction === value.id)?.reduce((acumulator, itemReduced) => acumulator + itemReduced.raw_material_amount, 0) ?? 0;
                let rawMaterialAvailable = value.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList;

                //new registration: adding a new item
                if ((selectedBeneficiationItem === null) && (selectedItemInput === null)) {
                  rawMaterialAvailable = value.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList;
                }

                //new registration: editing an existing item
                if ((selectedBeneficiationItem === null) && (selectedItemInput !== null)) {
                  rawMaterialAvailable = value.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList + selectedItemInput.raw_material_amount;
                }

                //register editing: adding a new item
                if ((selectedBeneficiationItem !== null) && (selectedItemInput === null)) {
                  rawMaterialAvailable = value.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList;
                }

                //editing a record: editing an existing item
                if ((selectedBeneficiationItem !== null) && (selectedItemInput !== null)) {
                  rawMaterialAvailable = value.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList + selectedItemInput.raw_material_amount;
                }

                setRawMaterialAmount(rawMaterialAvailable);
                setRawMaterial(value);
              }}
            ></Autocomplete>
            <Numberfield
              label="Quantidade de Matéria-prima*"
              width={230}
              disabled={false}
              value={rawMaterialAmount}
              fontSize={14}
              onChange={(value) => {
                if (value >= 0) {
                  if (rawMaterial && rawMaterial.id) {
                    const rawMaterialInBeneficiationItemsList = getTotalAmountRawMaterialInBeneficiationItemsList(rawMaterial.id);
                    const rawMaterialInItemsList = itemsList?.filter((item) => item.raw_material_transaction === rawMaterial.id)?.reduce((acumulator, itemReduced) => acumulator + itemReduced.raw_material_amount, 0) ?? 0;
                    let rawMaterialAvailable = 0;

                    //new registration: adding a new item
                    if ((selectedBeneficiationItem === null) && (selectedItemInput === null)) {
                      rawMaterialAvailable = rawMaterial.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList;
                    }

                    //new registration: editing an existing item
                    if ((selectedBeneficiationItem === null) && (selectedItemInput !== null)) {
                      rawMaterialAvailable = rawMaterial.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList + selectedItemInput.raw_material_amount;
                    }

                    //register editing: adding a new item
                    if ((selectedBeneficiationItem !== null) && (selectedItemInput === null)) {
                      rawMaterialAvailable = rawMaterial.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList;
                    }

                    //editing a record: editing an existing item
                    if ((selectedBeneficiationItem !== null) && (selectedItemInput !== null)) {
                      rawMaterialAvailable = rawMaterial.amount - rawMaterialInBeneficiationItemsList - rawMaterialInItemsList + selectedItemInput.raw_material_amount;
                    }

                    if (value <= rawMaterialAvailable) {
                      setRawMaterialAmount(value);
                    } else {
                      setRawMaterialAmount(rawMaterialAvailable);

                      setSnack({
                        open: true,
                        severity: 'error',
                        message: 'A quantidade de matéria-prima informada não pode ser maior que soma da quantidade utilizada em outros itens. Ela foi atualizada para o maior valor possível.',
                      });
                    }
                  } else {
                    setSnack({
                      open: true,
                      severity: 'error',
                      message: 'Escolha uma matéria-prima antes de informar a quantidade a ser utilizada no item de beneficiamento.',
                    });
                  }
                }
              }}
            ></Numberfield>
            <Numberfield
              label="Peixe Fraco*"
              width={230}
              disabled={false}
              value={weakFish}
              fontSize={14}
              onChange={(value) => {
                if (value >= 0) {
                  if (value < rawMaterialAmount) {
                    setWeakFish(value);
                  } else {
                    setWeakFish(0);

                    setSnack({
                      open: true,
                      severity: 'error',
                      message: 'O peixe fraco não pode ser maior ou igual à quantidade de matéria-prima.',
                    });
                  }
                }
              }}
            ></Numberfield>
            <Numberfield
              label="Peixe Líquido"
              width={230}
              disabled={true}
              value={netFish}
              onChange={() => { }}
              fontSize={14}
            ></Numberfield>
          </Row>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={finishedProduct}
                  width={230}
                  disabled={false}
                  options={finishedProductsOptions}
                  fontSize={14}
                  label={'Produto Acabado*'}
                  onChange={setFinishedProduct}
                ></Autocomplete>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Quantidade Produzida*"
                  width={230}
                  disabled={false}
                  value={producedAmount}
                  fontSize={14}
                  onChange={(value) => {
                    if (value >= 0) {
                      if (rawMaterial && rawMaterial.id) {
                        if (rawMaterialAmount > 0) {
                          if (selectedItemInput) {//edition
                            if (value <= rawMaterialAmount - weakFish) {
                              if (value >= selectedItemInput.withdrawn_amount) {
                                setProducedAmount(value);
                              } else {
                                setProducedAmount(selectedItemInput.withdrawn_amount);

                                setSnack({
                                  open: true,
                                  severity: 'error',
                                  message: 'A quantidade informada não pode ser menor que a quantidade de vendas do produto acabado selecionado. Ela foi atualizada para o menor valor possível.',
                                });
                              }
                            } else {
                              setProducedAmount(rawMaterialAmount - weakFish);

                              setSnack({
                                open: true,
                                severity: 'error',
                                message: 'A quantidade informada não pode ser maior que a quantidade de matéria-prima selecionada subtraída do peixe fraco. Ela foi atualizada para o maior valor possível.',
                              });
                            }
                          } else {//register
                            if (value <= rawMaterialAmount - weakFish) {
                              setProducedAmount(value);
                            } else {
                              setProducedAmount(rawMaterialAmount - weakFish);

                              setSnack({
                                open: true,
                                severity: 'error',
                                message: 'A quantidade informada não pode ser maior que a quantidade de matéria-prima selecionada subtraída do peixe fraco. Ela foi atualizada para o maior valor possível.',
                              });
                            }
                          }
                        } else {
                          setSnack({
                            open: true,
                            severity: 'error',
                            message: 'A quantidade de matéria-prima deve ser informada antes de se informar a quantidade produzida.',
                          });
                        }
                      } else {
                        setSnack({
                          open: true,
                          severity: 'error',
                          message: 'Escolha uma matéria-prima antes de informar a quantidade original produzida.',
                        });
                      }
                    }
                  }}
                ></Numberfield>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Custo do Beneficiamento*"
                  width={230}
                  disabled={false}
                  value={unitPrice}
                  fontSize={14}
                  onChange={(value) => {
                    if (value >= 0) {
                      setUnitPrice(value);
                    }
                  }}
                ></Numberfield>
              </div>
            </div>
          </Row>
          <Row style={{ justifyContent: 'right', marginBottom: 16 }}>
            <div style={{ display: 'inline-flex', height: 37, paddingRight: 13 }}>
              <Button
                label={buttonItemLabel}
                background="transparent"
                color="#256CE1"
                borderColor="#256CE1"
                disabled={false}
                onClick={addOrSaveItemToList}
              ></Button>
            </div>
          </Row>
          <Table
            columns={columnsItems}
            rows={itemsList || []}
            hasEditing={true}
            hasRemoving={true}
            setSelectedItem={handleItemFromList}
            height="auto"
          ></Table>
          <Row>
            <Explanation>
              Total do beneficiamento sem desconto: {formatMoney(sumTotalValueItems)}
            </Explanation>
          </Row>
          {renderNonParcelArea()}
          {renderParcelArea()}
          <Divider />
          <Row>
            <Textfield
              label="Observações"
              width={'100%'}
              disabled={false}
              value={notes}
              fontSize={14}
              onChange={setNotes}
              multiline={true}
              maxLength={500}
            ></Textfield>
          </Row>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: "right", marginTop: 0, marginBottom: '16px', paddingRight: '13px' }}>
            <Button
              label="Salvar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={disableSaveButton}
              onClick={saveOrEditBeneficiationItemAndUpdateList}
            ></Button>
            <Button
              label="Limpar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={false}
              onClick={cleanAll}
            ></Button>
          </Row>
          {(!selectedItem.finished_product_order_beneficiation) && (
            <TableNestedItemsAndParcels
              columns={columns}
              rows={beneficiationItemsList || []}
              columnsParcels={columnsParcels}
              columnsItems={columnsItems}
              hasEditing={true}
              hasRemoving={true}
              setSelectedItem={handleSelectedBeneficiationItem}
              height="auto"
            ></TableNestedItemsAndParcels>
          )}
        </Content >
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Itens de Beneficiamento do Pedido de Produto Acabado</h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}
        {showModalRemoveBeneficiationItem && (
          <ModalRemoveBeneficiationItem
            open={true}
            width={600}
            height={220}
            selectedItem={selectedBeneficiationItem}
            setSelectedItem={setSelectedBeneficiationItem}
            setShowModalRemoveBeneficiationItem={setShowModalRemoveBeneficiationItem}
            setRefreshDataFromServer={setRefreshDataFromServer}
          />
        )}
        {showModalVisualizeBeneficiationItem && (
          <ModalVisualizeBeneficiationItem
            open={true}
            width={875}
            height={400}
            selectedItem={selectedBeneficiationItem}
            setSelectedItem={setSelectedBeneficiationItem}
            setShowModalVisualizeBeneficiationItem={setShowModalVisualizeBeneficiationItem}
            columnsParcels={columnsParcels}
            columnsItems={columnsItems}
          />
        )}
      </Container>
    </Modal>
  )
}