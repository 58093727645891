import { useState, useContext, useEffect } from "react";
import Button from "../../../components/Button";
import Numberfield from "../../../components/Numberfield";
import { RawDatepicker } from "../../../components/Datepicker/index";
import Textfield from "../../../components/Textfield";
import { Container, Header, Row, Content, Explanation } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseProducts } from "../../../hooks/useProducts";
import Autocomplete from "../../../components/Autocomplete";
import AppContext from "../../../state/App.context";
import Table from '../../../components/Table';
import { UseFinishedProductOrders } from "../../../hooks/useFinishedProductOrders";
import { UseOrders } from "../../../hooks/useOrders";
import { UseBeneficiationCompanies } from "../../../hooks/useBeneficiationCompanies";
import { UseProviders } from "../../../hooks/useProviders";
import moment from "moment";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UsePaymentTypes } from "../../../hooks/usePaymentTypes";
import { formatMoney } from "../../../services/functions";
import ModalRemoveItemSale from "../ModalRemoveItemSale";
import TableNestedItemsAndParcels from "../../../components/TableNestedItemsAndParcels";
import ModalVisualizeItemSale from "../ModalVisualizeItemSale";

const columns = [
  {
    name: 'Data da Venda',
    key: 'dt_sale',
    type: 'date',
    unit: '',
  },
  {
    name: 'Comprador',
    key: 'provider_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Vendedor',
    key: 'seller_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Produto Beneficiado',
    key: 'finished_product_order_item_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Preço Unitário',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Desconto',
    key: 'discount',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Tipo de Pagamento',
    key: 'payment_type_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Categoria de Custo',
    key: 'cost_category_name',
    type: 'string',
    unit: '',
  },
]

const columnsParcels = [
  {
    name: 'Nº',
    key: 'number',
    type: 'number',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Vencimento',
    key: 'expiration_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Pagamento',
    key: 'payment_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'string',
    unit: '',
  },

]

const statusOptions = [
  {
    id: 0,
    label: 'Pendente'
  },
  {
    id: 1,
    label: 'Executado',
  }
]

export default function ModalManageItemsSale({ open, width, height, selectedItem, setSelectedItem, setRefreshData }) {
  const { products } = UseProducts();
  const { providers } = UseProviders();
  const { costCategories } = UseCostCategories();
  const { paymentTypes } = UsePaymentTypes();
  const { getOrderTransactions } = UseOrders();
  const { getBeneficiationCompanies } = UseBeneficiationCompanies();
  const { getFinishedProductOrderBeneficiationItems, createFinishedProductSale,
    updateFinishedProductSale, getFinishedProductOrderSales } = UseFinishedProductOrders();

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const [showModalRemoveItemSale, setShowModalRemoveItemSale] = useState(false);
  const [showModalVisualizeItemSale, setShowModalVisualizeItemSale] = useState(false);

  const [selectedItemSale, setSelectedItemSale] = useState(null);

  const [dtSale, setDtSale] = useState(moment().format('YYYY-MM-DD'));
  const [buyer, setBuyer] = useState({ id: 0, label: '' });
  const [seller, setSeller] = useState({ id: 0, label: '' });
  const [finishedProduct, setFinishedProduct] = useState({ id: 0, label: '' });
  const [amount, setAmount] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [costCategory, setCostCategory] = useState({ id: 0, label: '' });
  const [paymentType, setPaymentType] = useState({ id: 0, label: '' });
  const [discount, setDiscount] = useState(0);
  const [notes, setNotes] = useState('');

  const [parcelValue, setParcelValue] = useState(0);
  const [expirationDate, setExpirationDate] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [status, setStatus] = useState(statusOptions[0]);

  const [selectedParcel, setSelectedParcel] = useState(null);
  const [buttonParcelLabel, setButtonParcelLabel] = useState('Adicionar');
  const [parcelsAmount, setParcelsAmount] = useState(0);
  const [parcelsList, setParcelsList] = useState([]);

  const [salesList, setSalesList] = useState([]);

  const [beneficiationItemsList, setBeneficiationItemsList] = useState([]);

  const [refreshDataFromServer, setRefreshDataFromServer] = useState(0);

  const [finishedProductOrderId, setFinishedProductOrderId] = useState('');

  const [saleToEdit, setSaleToEdit] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let id_finished_product_order = '';
      let id_order = '';

      if (selectedItem.id_order && selectedItem.finished_product_order_input_order && selectedItem.finished_product_order) {
        //finantial schedule screen
        id_finished_product_order = selectedItem.finished_product_order;
        id_order = selectedItem.finished_product_order_input_order;
      } else {
        //modal finished product order modal
        id_finished_product_order = selectedItem.item?.id_finished_product_order;
        id_order = selectedItem.item?.id_order;
      }

      setFinishedProductOrderId(id_finished_product_order);

      try {
        // Carrega produtos
        let responseTransactions = await getOrderTransactions(id_order);

        if (responseTransactions.success) {
          const activeTransactions = responseTransactions?.data.filter(element => element.isactive);

          // Carrega empresas beneficiadoras (dependente do estado anterior)
          let responseBeneficiationCompanies = await getBeneficiationCompanies();

          if (responseBeneficiationCompanies.success) {
            const companies = responseBeneficiationCompanies?.data.map(beneficiationCompany => ({
              id: beneficiationCompany.id,
              label: beneficiationCompany.name,
            }));

            // Carrega itens do pedido (dependente dos dois anteriores)
            let responseBeneficiationItems = await getFinishedProductOrderBeneficiationItems(id_finished_product_order);

            if (responseBeneficiationItems.success) {
              const items = responseBeneficiationItems?.data.map(beneficiationItem => ({
                id: beneficiationItem.id,
                finished_product_order: beneficiationItem.finished_product_order,
                beneficiation_company: beneficiationItem.beneficiation_company,
                beneficiation_company_name: companies.find((company) => company.id === beneficiationItem.beneficiation_company)?.label,
                batch: beneficiationItem.batch,
                discount: beneficiationItem.discount,
                notes: beneficiationItem.notes,
                payment_type: beneficiationItem.payment_type,
                payment_type_name: paymentTypes.find((paymentType) => paymentType.id_paymenttype === beneficiationItem.payment_type).paymenttype_name,
                total_value: beneficiationItem.items?.reduce((total, item) => total + ((item.raw_material_amount - item.weakfish) * item.unit_price), 0) - beneficiationItem.discount,
                parcels: beneficiationItem.parcels?.map((parcel) => ({
                  number: parcel.number,
                  value: parcel.value,
                  expiration_date: parcel.expiration_date,
                  payment_date: parcel.payment_date,
                  status: parcel.status,
                })),
                items: beneficiationItem.items?.map((item, index) => ({
                  number: index + 1,
                  id: item.id,
                  raw_material_transaction: item.raw_material_transaction,
                  raw_material_name: activeTransactions.find((transaction) => transaction.id_transaction === item.raw_material_transaction)?.product_name,
                  raw_material_amount: item.raw_material_amount ?? 0,
                  finished_product: item.finished_product,
                  finished_product_name: products.find((product) => product.id_product === item.finished_product)?.product_name,
                  produced_amount: item.produced_amount,
                  unit_price: item.unit_price,
                  total_value: (item.raw_material_amount - item.weakfish) * item.unit_price,
                  weakfish: item.weakfish,
                  withdrawn_amount: item.withdrawn_amount,
                })),
              }));

              setBeneficiationItemsList(items);

              const getFinishedProductName = (id) => {
                for (const bItem of items) {
                  let aux = bItem.items?.find((item) => item.id === id);

                  if (aux) {
                    return aux.finished_product_name;
                  }
                }
              }

              let responseSales = await getFinishedProductOrderSales(id_finished_product_order);

              if (responseSales.success) {
                const sales = [];

                responseSales?.data?.forEach(sale => {
                  const saleFormatted = {
                    id: sale.id,
                    dt_sale: sale.dt_sale,
                    provider: sale.provider,
                    provider_name: providers.find((provider) => provider.id_providers === sale.provider).providers_name,
                    seller: sale.seller,
                    seller_name: providers.find((provider) => provider.id_providers === sale.seller).providers_name,
                    finished_product_order_item: sale.finished_product_order_item,
                    finished_product_order_item_name: getFinishedProductName(sale.finished_product_order_item),
                    amount: sale.amount,
                    unit_price: sale.unit_price,
                    total_value: sale.amount * sale.unit_price - sale.discount,
                    cost_category: sale.cost_category,
                    cost_category_name: costCategories.find((costCategory) => costCategory.id_category === sale.cost_category).costs_categories_name,
                    payment_type: sale.payment_type,
                    payment_type_name: paymentTypes.find((paymentType) => paymentType.id_paymenttype === sale.payment_type).paymenttype_name,
                    discount: sale.discount,
                    notes: sale.notes,
                    parcels: sale.parcels?.map((parcel) => ({
                      number: parcel.number,
                      value: parcel.value,
                      expiration_date: parcel.expiration_date,
                      payment_date: parcel.payment_date,
                      status: parcel.status,
                    })),
                  };

                  if (selectedItem.id_order && (selectedItem.id_order === sale.id)) {
                    setSaleToEdit({ ...saleFormatted });
                  }

                  sales.push(saleFormatted);
                });

                setSalesList(sales);
              } else {
                setSnack({
                  open: true,
                  severity: 'error',
                  message: responseSales?.message,
                });
              }
            } else {
              setSnack({
                open: true,
                severity: 'error',
                message: responseBeneficiationItems?.message,
              });
            }
          } else {
            setSnack({
              open: true,
              severity: 'error',
              message: responseBeneficiationCompanies?.message,
            });
          }
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: responseTransactions?.message,
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDataFromServer]);

  function cleanAllInputs() {
    setDtSale(moment().format('YYYY-MM-DD'));
    setBuyer({ id: 0, label: '' });
    setSeller({ id: 0, label: '' });
    setFinishedProduct({ id: 0, label: '' });
    setAmount(0);
    setUnitPrice(0);
    setCostCategory({ id: 0, label: '' });
    setPaymentType({ id: 0, label: '' });
    setDiscount(0);
    setNotes('');
    setParcelValue(0);
    setExpirationDate('');
    setPaymentDate('');
    setStatus(statusOptions[0]);
    setParcelsAmount(0);
    setParcelsList([]);
    setButtonParcelLabel('Adicionar');
    setSelectedParcel(null);
  }

  function cleanAll() {
    cleanAllInputs();
    setSelectedItemSale(null);
  }

  let buyersOptions = providers && !providers.error && providers.filter(a => a.type === 'Comprador').map((buyer) => ({
    id: buyer?.id_providers,
    label: buyer?.providers_name,
  }));

  let sellersOptions = providers && !providers.error && providers.filter(a => a.type === 'Vendedor').map((seller) => ({
    id: seller?.id_providers,
    label: seller?.providers_name,
  }));

  let beneficiationItemsOptions = [];

  beneficiationItemsList.map((beneficiationItem) => (
    beneficiationItem.items?.map((item) => (
      beneficiationItemsOptions.push({
        id: item.id,
        label: item.finished_product_name,
        stock: item.produced_amount - item.withdrawn_amount,
      })
    ))
  ));

  let costCategoriesOptions = costCategories && !costCategories.error && costCategories.map((element) => (
    {
      id: element?.id_category,
      label: element?.costs_categories_name,
    }
  ));

  let paymentTypesOptions = paymentTypes && !paymentTypes.error && paymentTypes.map((element) => {
    return {
      id: element?.id_paymenttype,
      label: element?.paymenttype_name,
    }
  });

  if ((saleToEdit) && (beneficiationItemsOptions.length > 0)) {
    handleSelectedItemSale({
      open: true,
      mode: "edit",
      ...saleToEdit,
    });

    setSaleToEdit(null);
  }

  async function saveOrEditItemSaleAndUpdateList(event) {
    try {
      setLoading(true);
      setDisableSaveButton(true);
      event.preventDefault();

      if (dtSale && moment(dtSale).isValid() && buyer && buyer.id &&
        seller && seller.id && finishedProduct && finishedProduct.id && (amount > 0) &&
        (unitPrice >= 0) && costCategory && costCategory.id &&
        paymentType && paymentType.id && (discount >= 0) && status &&
        status.label) {

        if (((paymentType.label !== 'Parcelado') && (moment(expirationDate).isValid())) || ((paymentType.label === 'Parcelado') && (parcelsSum === saleValue))) {
          let item_sale_id = null;

          if (selectedItemSale && selectedItemSale.id) {
            item_sale_id = selectedItemSale.id;
          }

          let parcelsListAux = null;

          if (paymentType.label !== 'Parcelado') {
            parcelsListAux = [{
              number: 1,
              value: saleValue,
              expiration_date: moment(expirationDate).isValid() ? expirationDate : null,
              payment_date: moment(paymentDate).isValid() ? paymentDate : null,
              status: status.label,
            }];

            setParcelsList([{
              ...parcelsListAux[0],
            }]);
          } else {
            parcelsListAux = parcelsList.map((parcel, index) => ({
              number: index + 1,
              value: parcel.value,
              expiration_date: moment(parcel.expiration_date).isValid() ? parcel.expiration_date : null,
              payment_date: moment(parcel.payment_date).isValid() ? parcel.payment_date : null,
              status: parcel.status,
            }));
          }

          if (item_sale_id) {
            let result = await updateFinishedProductSale(item_sale_id, {
              dt_sale: dtSale,
              provider: buyer.id,
              seller: seller.id,
              finished_product_order_item: finishedProduct.id,
              amount: amount,
              unit_price: unitPrice,
              cost_category: costCategory.id,
              payment_type: paymentType.id,
              discount: discount,
              notes: notes,
              parcels: parcelsListAux,
            });

            if (result.success) {
              setSnack({
                open: true,
                severity: 'success',
                message: 'Venda de produto acabado atualizada com sucesso!',
              });

              if (selectedItem.id_order) {
                handleClose();
              } else {
                setRefreshDataFromServer((prevValue) => prevValue + 1);
                cleanAll();
              }
            } else {
              setSnack({
                open: true,
                severity: 'error',
                message: 'Não foi possível atualizar o cadastro da venda de produto acabado: ' + result.message,
              });
            }
          } else {
            let result = await createFinishedProductSale({
              dt_sale: dtSale,
              provider: buyer.id,
              seller: seller.id,
              finished_product_order_item: finishedProduct.id,
              amount: amount,
              unit_price: unitPrice,
              cost_category: costCategory.id,
              payment_type: paymentType.id,
              discount: discount,
              notes: notes,
              parcels: parcelsListAux,
            });

            if (result.success) {
              setRefreshDataFromServer((prevValue) => prevValue + 1);
              cleanAll();

              setSnack({
                open: true,
                severity: 'success',
                message: 'Venda de produto acabado cadastrada com sucesso!',
              });
            } else {
              setSnack({
                open: true,
                severity: 'error',
                message: 'Não foi possível realizar o cadastro da venda de produto acabado: ' + result.message,
              });
            }
          }
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: 'A soma das parcelas deve corresponder ao valor total da venda!',
          });
        }
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Preencha todos os campos necessários!',
        });
      }
    } catch (err) {
      console.log(err);
      setSnack({
        open: true,
        severity: 'error',
        message: 'err',
      });
    } finally {
      setLoading(false);
      setDisableSaveButton(false);
    }
  }

  function handleSelectedItemSale(item) {
    setSelectedItemSale(item);

    if (item.mode === 'edit') {
      setDtSale(item.dt_sale);
      setBuyer({ id: item.provider, label: item.provider_name });
      setSeller({ id: item.seller, label: item.seller_name });
      setFinishedProduct({ id: item.finished_product_order_item, label: item.finished_product_order_item_name, stock: beneficiationItemsOptions.find((beneficiationItem) => beneficiationItem.id === item.finished_product_order_item).stock });
      setAmount(item.amount);
      setUnitPrice(item.unit_price);
      setCostCategory({ id: item.cost_category, label: item.cost_category_name });
      setPaymentType({ id: item.payment_type, label: item.payment_type_name });
      setDiscount(item.discount);
      setNotes(item.notes);

      if (item.payment_type_name !== 'Parcelado') {
        setExpirationDate(item.parcels[0].expiration_date);
        setPaymentDate(item.parcels[0].payment_date);

        if (item.parcels[0].status === 'Executado') {
          setStatus(statusOptions[1]);
        } else {
          setStatus(statusOptions[0]);
        }
      } else {
        setExpirationDate('');
        setPaymentDate('');
        setStatus(statusOptions[0]);
      }

      setParcelsList(item.parcels);
      setParcelsAmount(item.parcels.length);
    }

    if (item.mode === 'remove') {
      setShowModalRemoveItemSale(true);
      cleanAllInputs();
    }

    if (item.mode === 'visualize') {
      setShowModalVisualizeItemSale(true);
    }
  }

  function handleClose() {
    cleanAll();

    if (selectedItem.id_order) {
      setSelectedItem({ open: false, mode: '', name: '', id_order: null });
      setRefreshData(prevIsRefreshData => !prevIsRefreshData);
    } else {
      setSelectedItem({ open: false, mode: 'view', item: null });
    }
  }

  function addOrSaveParcelToList() {
    if ((parcelValue > 0) && expirationDate && moment(expirationDate).isValid() && status && status.label) {

      if (parcelValue + parcelsSum <= saleValue) {
        try {
          let parcelsCopy = [...parcelsList];

          if (buttonParcelLabel === 'Adicionar') {
            let parcelInfo = {
              number: parcelsAmount + 1,
              value: parcelValue,
              expiration_date: expirationDate,
              payment_date: paymentDate,
              status: status.label,
            }

            parcelsCopy.push(parcelInfo);
            setParcelsList(parcelsCopy);
            setParcelsAmount(parcelsAmount + 1);
          }

          if (buttonParcelLabel === 'Alterar') {
            let parcelInfo = {
              ...selectedParcel,
              value: parcelValue,
              expiration_date: expirationDate,
              payment_date: paymentDate,
              status: status.label,
            };

            let index = parcelsCopy.findIndex((element) => element.number === selectedParcel.number);
            parcelsCopy.splice(index, 1, parcelInfo);
            setParcelsList(parcelsCopy);
            setSelectedParcel(null);
            setButtonParcelLabel('Adicionar');
          }

          setParcelValue(0);
          setExpirationDate('');
          setPaymentDate('');
          setStatus(statusOptions[0]);
        } catch (err) {
          console.log(err);
        }
      } else {
        setParcelValue(saleValue - parcelsSum);

        setSnack({
          open: true,
          severity: 'error',
          message: 'O somatório das parcelas será maior do que o valor da venda. O valor da parcela foi atualizado para o máximo permitido.',
        });
      }
    } else {
      setSnack({
        open: true,
        severity: 'error',
        message: 'O valor e a data de vencimento devem ser informados.',
      });
    }
  }

  function handleParcelFromList(selectedParcelItem) {
    try {
      if (selectedParcelItem.mode === 'remove') {
        let parcelsCopy = [...parcelsList];
        let index = parcelsCopy.findIndex((element) => element.number === selectedParcelItem.number);

        if (index > -1) {
          let parcelRemoved = parcelsCopy.splice(index, 1);

          if (parcelRemoved && parcelRemoved.length > 0) {
            setParcelsAmount(parcelsAmount - 1);

            setParcelsList(parcelsCopy.map((parcel, index) => ({
              ...parcel,
              number: index + 1,
            })));

            setSelectedParcel(null);
            setButtonParcelLabel('Adicionar');
          }
        }
      }

      if (selectedParcelItem.mode === 'edit') {
        setButtonParcelLabel('Alterar')
        setSelectedParcel(selectedParcelItem);
        setParcelValue(selectedParcelItem.value);
        setExpirationDate(selectedParcelItem.expiration_date);
        setPaymentDate(selectedParcelItem.payment_date);

        if (selectedParcelItem.status === 'Executado') {
          setStatus(statusOptions[1]);
        } else {
          setStatus(statusOptions[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  let saleValue = amount * unitPrice - discount;

  let parcelsSum = 0;

  for (let i = 0; i < parcelsList.length; i++) {
    if (parcelsList[i]?.value !== undefined && !isNaN(parcelsList[i]?.value)) {
      if (buttonParcelLabel === 'Adicionar') {
        parcelsSum += parcelsList[i]?.value;
      } else if (buttonParcelLabel === 'Alterar') {
        if (parcelsList[i]?.number !== selectedParcel.number) {
          parcelsSum += parcelsList[i]?.value;
        }
      }
    }
  }

  function renderParcelArea() {
    if (paymentType.label === 'Parcelado') {
      return (
        <>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Numberfield
                  label="Desconto"
                  width={230}
                  disabled={false}
                  value={discount}
                  fontSize={14}
                  onChange={setDiscount}
                ></Numberfield>
              </div>
            </div>
          </Row>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top', marginTop: -8 }}>
              <div style={{ width: 253 }}>
                <Numberfield
                  label="Valor da Parcela*"
                  width={230}
                  disabled={false}
                  value={parcelValue}
                  fontSize={14}
                  onChange={setParcelValue}
                ></Numberfield>
              </div>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Vencimento da Parcela*"
                  width={230}
                  disabled={false}
                  value={expirationDate}
                  fontSize={14}
                  onChange={setExpirationDate}
                />
              </div>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Pagamento da Parcela"
                  width={230}
                  disabled={false}
                  value={paymentDate}
                  fontSize={14}
                  onChange={setPaymentDate}
                />
              </div>
              <div style={{ width: 253, marginTop: 8 }}>
                <Autocomplete
                  value={status}
                  width={230}
                  disabled={false}
                  options={statusOptions}
                  fontSize={14}
                  label="Status*"
                  onChange={setStatus}
                ></Autocomplete>
              </div>
            </div>
          </Row>
          <Row style={{ justifyContent: 'right', marginBottom: 16 }}>
            <div style={{ display: 'inline-flex', height: 37, paddingRight: 13 }}>
              <Button
                label={buttonParcelLabel}
                background="transparent"
                color="#256CE1"
                borderColor="#256CE1"
                disabled={parcelsSum >= saleValue}
                onClick={addOrSaveParcelToList}
              ></Button>
            </div>
          </Row>
          <Table
            columns={columnsParcels}
            rows={parcelsList || []}
            hasEditing={true}
            hasRemoving={true}
            setSelectedItem={handleParcelFromList}
            height="auto"
          ></Table>
          <Row>
            <Explanation>
              Valor total da venda: {formatMoney(saleValue)}
              <br></br>
              Somatório das parcelas: <b>{formatMoney(parcelsSum)}</b>
            </Explanation>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Explanation style={{ color: 'var(--red)' }}>
              {saleValue !== parcelsSum ? 'O somátório das parcelas deve ser igual ao valor total da venda!' : ''}
            </Explanation>
          </Row>
        </>
      )
    }
  }

  function renderNonParcelArea() {
    if (paymentType.label !== 'Parcelado') {
      return (
        <>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Numberfield
                  label="Desconto"
                  width={230}
                  disabled={false}
                  value={discount}
                  fontSize={14}
                  onChange={setDiscount}
                ></Numberfield>
              </div>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Vencimento*"
                  width={230}
                  disabled={false}
                  value={expirationDate}
                  fontSize={14}
                  onChange={setExpirationDate} />
              </div>
              <div style={{ width: 253 }}>
                <RawDatepicker
                  type="date"
                  label="Data de Pagamento"
                  width={230}
                  disabled={false}
                  value={paymentDate}
                  fontSize={14}
                  onChange={setPaymentDate} />
              </div>
              <div style={{ width: 253, marginTop: 8 }}>
                <Autocomplete
                  value={status}
                  width={230}
                  disabled={false}
                  options={statusOptions}
                  fontSize={14}
                  label="Status*"
                  onChange={setStatus}
                ></Autocomplete>
              </div>
            </div>
          </Row>
        </>
      )
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row><h2>Id do pedido de produto acabado: {finishedProductOrderId}</h2></Row>
          <Row>
            <RawDatepicker
              type="date"
              label="Data da Venda"
              width={230}
              disabled={false}
              value={dtSale}
              fontSize={14}
              onChange={setDtSale}
            />
            <Autocomplete
              value={buyer}
              width={230}
              disabled={false}
              options={buyersOptions}
              fontSize={14}
              label={'Comprador*'}
              onChange={setBuyer}
            ></Autocomplete>
            <Autocomplete
              value={seller}
              width={230}
              disabled={false}
              options={sellersOptions}
              fontSize={14}
              label={'Vendedor*'}
              onChange={setSeller}
            ></Autocomplete>
            <Autocomplete
              value={costCategory}
              width={230}
              disabled={false}
              options={costCategoriesOptions}
              fontSize={14}
              label={'Categoria de Custo*'}
              onChange={setCostCategory}
            ></Autocomplete>
          </Row>
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={finishedProduct}
                  width={230}
                  disabled={false}
                  options={beneficiationItemsOptions}
                  fontSize={14}
                  label={'Produto Acabado*'}
                  onChange={(value) => {
                    if (selectedItemSale) {// is an sale edition
                      if (selectedItemSale.finished_product_order_item === value.id) {//finished product not changed
                        setAmount(value.stock + selectedItemSale.amount);
                      } else {//finished product changed
                        setAmount(value.stock);
                      }
                    } else {//is a sale register
                      setAmount(value.stock);
                    }

                    setFinishedProduct(value);
                  }}
                ></Autocomplete>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Quantidade*"
                  width={230}
                  disabled={false}
                  value={amount}
                  fontSize={14}
                  onChange={(value) => {
                    if (finishedProduct && finishedProduct.id) {
                      if (selectedItemSale) {// is an sale edition
                        if (selectedItemSale.finished_product_order_item === finishedProduct.id) {//finished product not changed
                          if (value <= (finishedProduct.stock + selectedItemSale.amount)) {
                            setAmount(value);
                          } else {
                            setAmount(finishedProduct.stock + selectedItemSale.amount);

                            setSnack({
                              open: true,
                              severity: 'error',
                              message: 'A quantidade precisa ser menor ou igual ao estoque disponível. A quantidade foi atualizada para a maior possível.',
                            });
                          }
                        } else {//finished product changed
                          if (value <= (finishedProduct.stock)) {
                            setAmount(value);
                          } else {
                            setAmount(finishedProduct.stock);

                            setSnack({
                              open: true,
                              severity: 'error',
                              message: 'A quantidade precisa ser menor ou igual ao estoque disponível. A quantidade foi atualizada para a maior possível.',
                            });
                          }
                        }
                      } else {//is a sale register
                        if (value <= finishedProduct.stock) {
                          setAmount(value);
                        } else {
                          setAmount(finishedProduct.stock);

                          setSnack({
                            open: true,
                            severity: 'error',
                            message: 'A quantidade precisa ser menor ou igual ao estoque disponível. A quantidade foi atualizada para a maior possível.',
                          });
                        }
                      }
                    } else {
                      setSnack({
                        open: true,
                        severity: 'error',
                        message: 'Escolha um produto acabado antes de especificar a quantidade.',
                      });
                    }
                  }}
                ></Numberfield>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Preço Unitário"
                  width={230}
                  disabled={false}
                  value={unitPrice}
                  fontSize={14}
                  onChange={setUnitPrice}
                ></Numberfield>
              </div>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={paymentType}
                  width={230}
                  disabled={false}
                  options={paymentTypesOptions}
                  fontSize={14}
                  label={'Tipo de Pagamento*'}
                  onChange={setPaymentType}
                ></Autocomplete>
              </div>
            </div>
          </Row>
          {renderNonParcelArea()}
          {renderParcelArea()}
          <Row>
            <Textfield
              label="Observações"
              width={'100%'}
              disabled={false}
              value={notes}
              fontSize={14}
              onChange={setNotes}
              multiline={true}
              maxLength={500}
            ></Textfield>
          </Row>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: "right", marginTop: '16px', marginBottom: '16px', paddingRight: '15px' }}>
            <Button
              label="Salvar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={disableSaveButton}
              onClick={saveOrEditItemSaleAndUpdateList}
            ></Button>
            <Button
              label="Limpar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={false}
              onClick={cleanAll}
            ></Button>
          </Row>
          {(!selectedItem.id_order) && (
            <TableNestedItemsAndParcels
              columns={columns}
              columnsParcels={columnsParcels}
              rows={salesList || []}
              hasEditing={true}
              hasRemoving={true}
              setSelectedItem={handleSelectedItemSale}
              height="auto"
            ></TableNestedItemsAndParcels>
          )}
        </Content >
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Venda de Produto Acabado</h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}
        {showModalRemoveItemSale && (
          <ModalRemoveItemSale
            open={true}
            width={600}
            height={220}
            selectedItem={selectedItemSale}
            setSelectedItem={setSelectedItemSale}
            setShowModalRemoveItemSale={setShowModalRemoveItemSale}
            setRefreshDataFromServer={setRefreshDataFromServer}
          />
        )}
        {showModalVisualizeItemSale && (
          <ModalVisualizeItemSale
            open={true}
            width={1000}
            height={450}
            selectedItem={selectedItemSale}
            setSelectedItem={setSelectedItemSale}
            setShowModalVisualizeItemSale={setShowModalVisualizeItemSale}
            columnsParcels={columnsParcels}
          />
        )}
      </Container>
    </Modal>
  )
}