import { TableContainer, TableHeader, TableRow, Icons, NoData } from "./styles";
import { Remove, Edit } from "../../assets/icons/index";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { formatNumber, formatMoney } from "../../services/functions";
import { NoDataImg } from "../../assets/images";

export default function Table({
  columns,
  rows,
  hasEditing,
  hasRemoving,
  setSelectedItem,
  height,
  actionsInFirstLine,
  colorfulRows,
  colorfulRowsById,
  fitWidth,
  onRowClick,
}) {
  function createDynamicStyle() {
    try {
      let elementToAdd = fitWidth ? "150px " : "1fr ";
      let styleString = "";
      columns.map((item) => (
        styleString = styleString + elementToAdd
      ));
      if (hasEditing || hasRemoving) {
        styleString = styleString + elementToAdd;
      }
      return styleString;
    } catch (err) {
      console.log(err);
    }
  }
  let columnsWidths = createDynamicStyle();

  function RenderActions({ rowIndex }) {
    if (hasEditing || hasRemoving) {
      return (
        <Icons key={rowIndex + "i"}>
          <Tooltip title="Editar" key="edit">
            <img
              src={Edit}
              alt="Editar"
              onClick={() => {
                setSelectedItem({
                  open: true,
                  mode: "edit",
                  ...rows[rowIndex],
                });
              }}
              style={{ display: hasEditing ? "flex" : "none" }}
            />
          </Tooltip>
          <Tooltip title="Remover" key="remove">
            <img
              src={Remove}
              alt="Remover"
              onClick={() => {
                setSelectedItem({
                  open: true,
                  mode: "remove",
                  ...rows[rowIndex],
                });
              }}
            />
          </Tooltip>
        </Icons>
      );
    } else {
      return <></>;
    }
  }

  // function getElementColor(row, column) {
  //   if (colorfulRows) {
  //     if (row.status_name === "Pendente" && column.key === "status_name") {
  //       return "#54577A";
  //     }
  //     if (row?.type === "Saída") {
  //       return "#FF4D5E";
  //     } else {
  //       return "#03A03E";
  //     }
  //   } else {
  //     return "#54577A";
  //   }
  // }

  let lastOrderId = null;
  let lastOrderColor = null;

  function getElementColor(row, column) {
    if (colorfulRowsById) {
      if (row.id_order === lastOrderId) {
        return lastOrderColor;
      } else {
        // Alterna entre duas cores
        lastOrderColor = lastOrderColor === "#063970" ? "#fd041a" : "#063970";
        lastOrderId = row.id_order;
        return lastOrderColor;
      }
    }

    if (colorfulRows) {
      if (row.status_name === "Pendente" && column.key === "status_name") {
        return "#54577A";
      }
      if (row?.type === "Saída") {
        return "#FF4D5E";
      } else {
        return "#03A03E";
      }
    } else {
      return "#54577A";
    }
  }


  function renderActionsRow(rowIndex) {
    return <RenderActions rowIndex={rowIndex}></RenderActions>;
  }

  function RenderRow({ rowIndex }) {
    return (
      <TableRow style={{ gridTemplateColumns: columnsWidths }} key={rowIndex}>
        {actionsInFirstLine && renderActionsRow(rowIndex)}
        {columns.map((column, index) => {
          const key = column.key;
          let value = rows[rowIndex][key];
          const unit = column.unit;
          if (column.type === "date") {
            if (value) {
              value = moment(value).add(3, "hours").format("DD/MM/YYYY");
            }
          }
          if (column.type === "datetime") {
            if (value) {
              value = moment(value)
                .add(3, "hours")
                .format("DD/MM/YYYY [às] HH:mm");
            }
          }
          if (column.type === "boolean") {
            value = value ? "Sim" : "Não";
          }
          if (column.type === "money") {
            value = value ? formatMoney(value) : "R$ 0,00";
          }
          if (column.type === "float") {
            value = formatNumber(value);
          }
          if (column.type === "percent") {
            value = formatNumber(value * 100);
          }
          let valueToShow = value || value === 0 ? value + unit : "-";
          return (
            <Tooltip title={value + unit || "-"} key={index + "-" + rowIndex}>
              <h3
                key={index + "-" + rowIndex}
                style={{ color: getElementColor(rows[rowIndex], column) }}
                onClick={() => {
                  setSelectedItem({
                    open: true,
                    mode: "visualize",
                    item: { ...rows[rowIndex] },
                  });
                  if (onRowClick) {
                    onRowClick(rows[rowIndex]);
                  }
                }}
              >
                {valueToShow}
              </h3>
            </Tooltip>
          );
        })}
        {!actionsInFirstLine && renderActionsRow(rowIndex)}
      </TableRow>
    );
  }

  function renderActionsHeader() {
    if (hasEditing || hasRemoving) {
      return <h3>Ações</h3>;
    }
  }

  return (
    <TableContainer
      style={{
        width: fitWidth ? "fit-content" : "100%",
        height: height || "500px",
      }}
    >
      <TableHeader style={{ gridTemplateColumns: columnsWidths }}>
        {actionsInFirstLine && renderActionsHeader()}
        {columns &&
          columns.map((column, index) => (
            <h3 key={column?.key}>{column?.name}</h3>
          ))}
        {!actionsInFirstLine && renderActionsHeader()}
      </TableHeader>
      {rows &&
        rows.length > 0 &&
        rows.map((row, index) => (
          <RenderRow key={index} rowIndex={index}></RenderRow>
        ))}
      {rows.length === 0 && (
        <NoData>
          <img alt="Nenhum registro encontrado" src={NoDataImg}></img>
          <h4>Sem registros </h4>
        </NoData>
      )}
    </TableContainer>
  );
}
