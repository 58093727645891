import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/Header";
import {
  Container,
  Content,
  Row,
  TripTitle,
  RowContent,
  LoadingContent,
} from "./styles";
import { useFinancialData } from "../../hooks/useFinancialData";
import Button from "../../components/Button";
import AppContext from "../../state/App.context";
import Boat from "../../components/Boat";
import OverviewGraph from "../../components/OverviewGraph";
import ResultsOverviewBox from "../../components/ResultsOverviewBox";
import { harvestOptions, tripsOptions } from "./options";
import OverviewFilters from "./Filters";
import Spinner from "../../components/Spinner";
import { Drawer } from "antd";

const defaultFilters = {
  harvest: harvestOptions[harvestOptions.length - 1],
  trip: {
    id: "Todas",
    label: "Todas",
  },
  boat: ["Todos"],
  fishingType: {
    id: "Todas",
    label: "Todas",
  },
  product: {
    id: "Todos",
    label: "Todos",
  },
  employee: {
    id: "Todos",
    label: "Todos",
  },
  costCategory: {
    id: "Todas",
    label: "Todas",
  },
  provider: {
    id: "Todos",
    label: "Todos",
  },
};

function objectValuesToArray(obj) {
  return Object.values(obj);
}

function groupByBoatsName(arr) {
  const grouped = arr.reduce((acc, item) => {
    const { boats_name, ...rest } = item;
    if (!acc[boats_name]) {
      acc[boats_name] = { boats_name, trips: [] };
    }
    acc[boats_name].trips.push(rest);
    return acc;
  }, {});

  return Object.values(grouped);
}

function Overview() {
  const { getCcOverview } = useFinancialData();
  const [loading, setLoading] = useState(true);
  const [boatsToShow, setBoatsToShow] = useState([]);
  const [info, setInfo] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const [filters, setFilters] = useState({ ...defaultFilters });

  function formatingBoats(groupedTrips, summary) {
    try {
      let direct_cost = 0;
      let indirect_cost = 0;
      let formattedBoats = [];
      groupedTrips.map((boat) => {
        let boat_daily_consumption = 0;
        let boat_net_kg = 0;
        let boat_balance = 0;
        let boat_gross_kg = 0;
        let boat_drop = 0;
        let boat_revenue = 0;
        let boat_expenses = 0;
        let boat_direct_cost = 0;
        let boat_indirect_cost = 0;
        let boat_direct_cost_per_kg = 0;
        let boat_indirect_cost_per_kg = 0;
        let trips = [];
        boat.trips?.map((trip) => {
          boat_daily_consumption += trip?.daily_oil_consumption;
          boat_net_kg += trip?.net_kg;
          boat_balance += trip?.balance;
          boat_gross_kg += trip?.gross_kg;
          boat_drop += trip?.drop;
          boat_revenue += trip?.revenue;
          boat_expenses += trip?.expenses;
          boat_direct_cost += trip?.direct_cost;
          boat_indirect_cost += trip?.indirect_cost;
          boat_direct_cost_per_kg += trip?.direct_cost_per_kg;
          boat_indirect_cost_per_kg = trip?.indirect_cost_per_kg;
          trips.push({ ...trip, harvest: filters?.harvest?.id });
        });
        formattedBoats.push({
          ...boat,
          trips,
          boat_daily_consumption,
          boat_net_kg,
          boat_balance,
          boat_gross_kg,
          boat_drop,
          boat_revenue,
          boat_expenses,
          boat_direct_cost,
          boat_indirect_cost,
          boat_indirect_cost_per_kg,
          boat_direct_cost_per_kg,
        });
        direct_cost += boat_direct_cost;
        indirect_cost += boat_indirect_cost;
      });
      setInfo({
        ...info,
        ...summary,
        direct_cost,
        indirect_cost,
      });
      return formattedBoats;
    } catch (err) {
      console.log(err);
    }
  }

  async function getInfo() {
    try {
      if (!loading) setLoading(true);
      let formattedFilters = {
        trips_name:
          filters?.trip?.label === "Todas" ? "''" : filters?.trip?.label,
        boats_name: filters?.boat.includes("Todos") ? "''" : filters?.boat,
        harvest:
          filters?.harvest?.label === "Todas" ? "''" : filters?.harvest?.label,
        fishingtypes_name:
          filters?.fishingType?.label === "Todas"
            ? "''"
            : filters?.fishingType?.label,
        product_name:
          filters?.product?.label === "Todos" ? "''" : filters?.product?.label,
        full_name:
          filters?.employee?.label === "Todos"
            ? "''"
            : filters?.employee?.label,
        costs_categories_name:
          filters?.costCategory?.label === "Todas"
            ? "''"
            : filters?.costCategory?.label,
        providers_name:
          filters?.provider?.label === "Todos"
            ? "''"
            : filters?.provider?.label,
      };
      let finantialResponse = await getCcOverview(formattedFilters);
      if (finantialResponse.success) {
        let array_trips = objectValuesToArray(
          finantialResponse?.data?.trips_summary
        );
        let trips_summary = array_trips && groupByBoatsName(array_trips);
        if (trips_summary.length > 0) {
          trips_summary = formatingBoats(
            trips_summary,
            finantialResponse?.data?.summary
          );
        }
        setBoatsToShow(trips_summary);
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: finantialResponse.message,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getInfo();
  }, [filters]);

  if (loading) {
    return (
      <Container>
        <Header title="Resumo geral" subtitle="Resumo das viagens por safra" />
        <Content>
          <Drawer
            title="Filtros"
            onClose={() => setOpenDrawer(false)}
            open={openDrawer}
          >
            <OverviewFilters
              filters={filters}
              setFilters={setFilters}
              defaultFilters={defaultFilters}
            ></OverviewFilters>
          </Drawer>
          <Row>
            <TripTitle>Safra {filters?.harvest?.id}</TripTitle>
            <Button
              label="Filtros"
              background="transparent"
              color="#54577A"
              borderColor="#54577A"
              disabled={false}
              onClick={() => setOpenDrawer(true)}
            ></Button>
          </Row>
          <Row style={{ width: "100%" }}>
            <TripTitle>Barcos</TripTitle>
          </Row>
          <RowContent>
            <OverviewGraph
              boats={boatsToShow || []}
              loading={loading}
            ></OverviewGraph>
            <ResultsOverviewBox
              info={info}
              loading={loading}
            ></ResultsOverviewBox>
          </RowContent>
          <LoadingContent>
            <Spinner width={40} fontSize={14}></Spinner>
          </LoadingContent>
        </Content>
      </Container>
    );
  }
  return (
    <Container>
      <Header title="Resumo geral" subtitle="Resumo das viagens por safra" />
      <Content>
        <Drawer
          title="Filtros"
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
          loading={loading}
          width={260}
        >
          <OverviewFilters
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
          ></OverviewFilters>
        </Drawer>
        <Row>
          <TripTitle>Safra {filters?.harvest?.id}</TripTitle>
          <Button
            label="Filtros"
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => setOpenDrawer(true)}
          ></Button>
        </Row>
        <Row style={{ width: "100%" }}>
          <TripTitle>Barcos</TripTitle>
        </Row>

        <RowContent>
          <OverviewGraph
            boats={boatsToShow || []}
            loading={loading}
          ></OverviewGraph>
          <ResultsOverviewBox
            info={info}
            loading={loading}
          ></ResultsOverviewBox>
        </RowContent>
        {boatsToShow &&
          boatsToShow.map((boat, index) => {
            return <Boat boat={boat} key={index}></Boat>;
          })}
      </Content>
    </Container>
  );
}

export default React.createElement(Overview);
