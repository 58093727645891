import { useState, useContext } from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import { Container, Header, Row, Content } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import { UseProducts } from "../../../../hooks/useProducts";
import AppContext from "../../../../state/App.context";

export default function ModalAddProduct({ open, handleOpen, width, height }) {
  const { createProduct } = UseProducts();
  const [productName, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;

  function cleanAllInputs() {
    try {
      setName('');
      setDescription('');
    } catch (err) {
      console.log(err);
    }
  }

  const title = 'Adicionar novo produto acabado';

  function checkingRequiredFields() {
    if (!productName) {
      setSnack({
        open: true,
        severity: 'error',
        message: 'Preencha todos os campos necessários!',
      });
      return false;
    }
    return true;
  }

  async function handleCreateNewProduct(event) {
    try {
      setLoading(true);
      event.preventDefault();

      if (checkingRequiredFields()) {
        const response = await createProduct(
          {
            product_name: productName,
            type: 'Produto Acabado',
            description,
          }
        );

        if (response.success) {
          cleanAllInputs();
          handleOpen(false);

          setSnack({
            open: true,
            severity: 'success',
            message: response?.message,
          })
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: 'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
          })
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row>
            <Textfield
              label="Nome do produto acabado*"
              width={600}
              disabled={false}
              value={productName}
              fontSize={14}
              onChange={setName}
              multiline={false}
              maxLength={60}
            ></Textfield>
          </Row>
          <Row>
            <Textfield
              label="Descrição"
              width={600}
              disabled={false}
              value={description}
              fontSize={14}
              onChange={setDescription}
              multiline={false}
              maxLength={300}
            ></Textfield>
          </Row>
          <Row>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={() => handleOpen(false)}
            ></Button>
            <Button
              label="Adicionar produto acabado"
              background="#256CE1"
              color="white"
              borderColor="#256CE1"
              disabled={false}
              onClick={handleCreateNewProduct}
            ></Button>
          </Row>
        </Content>
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={() => handleOpen(false)} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}