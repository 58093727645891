import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header";
import {
  Container,
  Content,
  Row,
  ReviewContainer,
  ReviewContent,
  RowInfo,
  Title,
  Divider,
  Tabs,
  Section,
} from "./styles";
import { useFinancialData } from "../../hooks/useFinancialData";
import Table from "../../components/OrderedTable";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import { Drawer } from "antd";
import { verifyInterval, formatMoney } from "../../services/functions";
import {
  columnsEmployees,
  columnsBuyers,
  columnsProviders,
  harvestOptions,
} from "./options";
import OverviewFilters from "./Filters";
import AppContext from "../../state/App.context";
import { useNavigate } from "react-router-dom";

const defaultFilters = {
  harvest: harvestOptions[harvestOptions.length - 1],
  trip: {
    id: "Todas",
    label: "Todas",
  },
  boat: ["Todos"],
  fishingType: {
    id: "Todas",
    label: "Todas",
  },
  product: {
    id: "Todos",
    label: "Todos",
  },
  employee: {
    id: "Todos",
    label: "Todos",
  },
  costCategory: {
    id: "Todas",
    label: "Todas",
  },
  provider: {
    id: "Todos",
    label: "Todos",
  },
};

function FinantialCC() {
  const { getCcOverview } = useFinancialData();
  const [search, setSearch] = useState("");
  const [financialData, setFinancialData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [tableEmployeesState, setTableEmployeesState] = useState("outputs");
  const [tableProvidersState, setTableProvidersState] = useState("inputs");
  const [boatsToShow, setBoatsToShow] = useState([]);
  const [info, setInfo] = useState({
    total_credit: 0,
    total_pay: 0,
    production: 0,
    sale: 0,
  });
  const [, setSnack] = useContext(AppContext).snackState;
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    navigate("/details", { state: rowData });
  };

  async function getInfo() {
    try {
      if (!loading) setLoading(true);
      let formattedFilters = {
        trips_name:
          filters?.trip?.label === "Todas" ? "''" : filters?.trip?.label,
        boats_name: filters?.boat.includes("Todos") ? "''" : filters?.boat,
        harvest:
          filters?.harvest?.label === "Todas" ? "''" : filters?.harvest?.label,
        fishingtypes_name:
          filters?.fishingType?.label === "Todas"
            ? "''"
            : filters?.fishingType?.label,
        product_name:
          filters?.product?.label === "Todos" ? "''" : filters?.product?.label,
        full_name:
          filters?.employee?.label === "Todos"
            ? "''"
            : filters?.employee?.label,
        costs_categories_name:
          filters?.costCategory?.label === "Todas"
            ? "''"
            : filters?.costCategory?.label,
        providers_name:
          filters?.provider?.label === "Todos"
            ? "''"
            : filters?.provider?.label,
      };
      let finantialResponse = await getCcOverview(formattedFilters);
      if (finantialResponse.success) {
        setFinancialData(finantialResponse?.data);
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: finantialResponse.message,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getInfo();
  }, [filters]);

  function Filters() {
    return (
      <OverviewFilters
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      ></OverviewFilters>
    );
  }

  const groupByType = (data) => {
    const grouped = { entradas: [], saidas: [] };
    data.forEach((item) => {
      if (item.type.toLowerCase().includes("entrada")) {
        grouped.entradas.push(item);
      } else if (
        item.type.toLowerCase().includes("saída") ||
        item.type.toLowerCase().includes("saida")
      ) {
        grouped.saidas.push(item);
      }
    });
    return grouped;
  };

  const groupedSalaryTrue =
    financialData && financialData.summary
      ? groupByType(financialData.issalaryTrue)
      : { entradas: [], saidas: [] };
  const groupedSalaryNull =
    financialData && financialData.summary
      ? groupByType(financialData.issalaryNull)
      : { entradas: [], saidas: [] };
  const getColor = (value) => {
    return value < 0 ? "#FF4D5E" : "#03A03E";
  };

  function renderReview() {
    if (loading) {
      return (
        <ReviewContainer>
          <Title>Resumo</Title>
          <ReviewContent>
            <Spinner width={40} fontSize={0}></Spinner>
          </ReviewContent>
        </ReviewContainer>
      );
    }
    return (
      <ReviewContainer>
        <ReviewContent>
          <Title>Entradas</Title>
          <Divider></Divider>
          <RowInfo>
            Total executado:{" "}
            <b style={{ color: "#03A03E" }}>
              {" "}
              {formatMoney(financialData?.summary.entradas.total_exec)}
            </b>
          </RowInfo>
          <RowInfo>
            Total pendente:{" "}
            <b style={{ color: "#03A03E" }}>
              {formatMoney(financialData?.summary.entradas.total_pend)}
            </b>
          </RowInfo>
          <RowInfo>
            <b>Total Global</b>:
            <b style={{ color: "#03A03E" }}>
              {" "}
              {formatMoney(financialData?.summary.entradas.total_global)}
            </b>
          </RowInfo>
        </ReviewContent>
        <ReviewContent>
          <Title>Saídas</Title>
          <Divider></Divider>
          <RowInfo>
            Total executado:{" "}
            <b style={{ color: "#FF4D5E" }}>
              {" "}
              {formatMoney(financialData?.summary.saidas.total_exec)}
            </b>
          </RowInfo>
          <RowInfo>
            Total pendente:{" "}
            <b style={{ color: "#FF4D5E" }}>
              {formatMoney(financialData?.summary.saidas.total_pend)}
            </b>
          </RowInfo>
          <RowInfo>
            <b>Total Global:</b>
            <b style={{ color: "#FF4D5E" }}>
              {" "}
              {formatMoney(financialData?.summary.saidas.total_global)}
            </b>
          </RowInfo>
        </ReviewContent>
        <ReviewContent>
          <Title>Saldos</Title>
          <Divider />
          <RowInfo>
            Saldo Executado:{" "}
            <b
              style={{
                color: getColor(
                  financialData?.summary.entradas.total_exec -
                    financialData?.summary.saidas.total_exec
                ),
              }}
            >
              {formatMoney(
                financialData?.summary.entradas.total_exec -
                  financialData?.summary.saidas.total_exec
              )}
            </b>
          </RowInfo>
          <RowInfo>
            Saldo Pendente:{" "}
            <b
              style={{
                color: getColor(
                  financialData?.summary.entradas.total_pend -
                    financialData?.summary.saidas.total_pend
                ),
              }}
            >
              {formatMoney(
                financialData?.summary.entradas.total_pend -
                  financialData?.summary.saidas.total_pend
              )}
            </b>
          </RowInfo>
          <RowInfo>
            <b>Saldo Global: </b>
            <b
              style={{ color: getColor(financialData?.summary.global_balance) }}
            >
              {formatMoney(financialData?.summary.global_balance)}
            </b>
          </RowInfo>
        </ReviewContent>
        <ReviewContent>
          <Title>Análise Custo Direto e indireto</Title>
          <Divider></Divider>
          <RowInfo>
            Total Custo Direto:
            <b style={{ color: "#FF4D5E" }}>
              {" "}
              {formatMoney(financialData?.summary.saidas.direct_cost)}
            </b>
          </RowInfo>
          <RowInfo>
            Total Custo Indireto:
            <b style={{ color: "#FF4D5E" }}>
              {" "}
              {formatMoney(financialData?.summary.saidas.indirect_cost)}
            </b>
          </RowInfo>
          <RowInfo>
            <b>Custo Indireto:</b>{" "}
            <b>
              {(
                financialData?.summary.saidas.direct_cost_percentage * 100
              ).toFixed(2)}
              %
            </b>{" "}
            | <b>Custo Direto:</b>{" "}
            <b>
              {(
                financialData?.summary.saidas.indirect_cost_percentage * 100
              ).toFixed(2)}
              %
            </b>
          </RowInfo>
        </ReviewContent>
      </ReviewContainer>
    );
  }

  function renderTableEmployees() {
    const IsBuyers = tableEmployeesState === "inputs";
    const isEmployeesOutputs = tableEmployeesState === "outputs";
    const commonStyles = {
      color: "#256CE1",
      borderBottom: "2px solid #256CE1",
      fontWeight: 600,
    };
    return (
      <Section>
        <h1>Funcionários</h1>
        <Divider></Divider>
        <Tabs>
          {/* <h4
            style={IsBuyers ? commonStyles : {}}
            onClick={() => setTableEmployeesState('inputs')}
          >
            Entradas
          </h4> */}
          <h4
            style={isEmployeesOutputs ? commonStyles : {}}
            onClick={() => setTableEmployeesState("outputs")}
          >
            Saídas
          </h4>
        </Tabs>
        <Table
          columns={columnsEmployees}
          rows={
            (IsBuyers
              ? groupedSalaryTrue.entradas
              : groupedSalaryTrue.saidas) || []
          }
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={() => {}}
          onRowClick={handleRowClick}
          defaultOrderKey="total_value_pend"
          direction="descending"
        />
      </Section>
    );
  }

  function renderTableProviders() {
    const IsBuyers = tableProvidersState === "inputs";
    const isProviders = tableProvidersState === "outputs";
    const commonStyles = {
      color: "#256CE1",
      borderBottom: "2px solid #256CE1",
      fontWeight: 600,
    };
    return (
      <Section>
        <h1>Compradores/Fornecedores</h1>
        <Divider></Divider>
        <Tabs>
          <h4
            style={IsBuyers ? commonStyles : {}}
            onClick={() => setTableProvidersState("inputs")}
          >
            Compradores
          </h4>
          <h4
            style={isProviders ? commonStyles : {}}
            onClick={() => setTableProvidersState("outputs")}
          >
            Fornecedores
          </h4>
        </Tabs>
        <Table
          columns={IsBuyers ? columnsBuyers : columnsProviders}
          rows={
            (IsBuyers
              ? groupedSalaryNull.entradas
              : groupedSalaryNull.saidas) || []
          }
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={() => {}}
          onRowClick={handleRowClick}
          defaultOrderKey="total_value_pend"
          direction="descending"
        />
      </Section>
    );
  }

  function renderContent() {
    if (loading) {
      return (
        <div style={{ width: "100%", height: 300 }}>
          <Spinner width={40} fontSize={14} />
        </div>
      );
    } else {
      return (
        <>
          {renderTableEmployees()}
          {renderTableProviders()}
        </>
      );
    }
  }

  return (
    <Container>
      <Header
        title="Resumo Financeiro"
        subtitle="Resumo dos dados financeiros"
      />
      <Content>
        <Drawer
          title="Filtros"
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
        >
          <OverviewFilters
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
          ></OverviewFilters>
        </Drawer>
        <Row>
          <Button
            label="Filtros"
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => setOpenDrawer(true)}
          ></Button>
        </Row>
        {renderReview()}
        {renderContent()}
      </Content>
    </Container>
  );
}

export default React.createElement(FinantialCC);
